<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import { CONTEXT_KEY_USER, DROPPLACE_LIST } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9} 変更前の希望受け渡し方法*/
  const currentPackageDropPlace = shipment.packageDropPlace;

  /** @type {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9} 変更後の希望受け渡し方法*/
  let changedPackageDropPlace = shipment.packageDropPlace;

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (
      changedPackageDropPlace !== undefined &&
      changedPackageDropPlace !== currentPackageDropPlace
    ) {
      // 受け渡し方法が新たに指定されていれば、登録に必要な条件が全てそろっていると判定
      isAllConditionsMet = true;
    }

    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};

    if (changedPackageDropPlace) {
      // 希望受け取り方法が指定されている場合
      updateShipmentEvent.packageDropPlace = changedPackageDropPlace;
    }

    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "packageDropPlace",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

{#if userContext.hasShippingPartnerAdminRole()}
  <div class="item" id="packageDropPlace">
    <div class="itemTh">希望受け渡し方法</div>
    <div class="itemTd">
      <div class="packageDropPlaceSelectArea">
        <label for="statusChange" class="packageDropPlaceLabel">
          <select
            id="statusChange"
            class="selectInput"
            bind:value={changedPackageDropPlace}
            on:change={onInputChange}
          >
            <option value="" disabled>選択してください</option>
            {#each DROPPLACE_LIST as item}
              <option value={item}
                >{$_(`classes.packageDropPlace.${item}`)}</option
              >
            {/each}
          </select>
        </label>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .packageDropPlaceSelectArea {
    .packageDropPlaceLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 486px;
      &::after {
        position: absolute;
        right: 19px;
        width: 10px;
        height: 7px;
        background-color: #666;
        clip-path: polygon(0 0, 100% 0, 50% 76%);
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        width: 100%;
        height: 2.6em;
        padding: 0.4em 30px 0.4em 0.8em;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }
      select:hover {
        border-color: #333;
      }
    }
  }
</style>
