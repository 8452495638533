<script>
  // @ts-nocheck

  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    RETURN_STATUS_COMPLETE,
    RETURN_STATUS_LIST,
    RETURN_STATUS_REQUESTING,
    RETURN_STATUS_RETURNING,
    RETURN_STATUS_WAITING,
    ReturnReason,
    STATUS_CREATED,
    STATUS_DELIVERED,
    STATUS_HELD_IN_DEPOT,
    STATUS_IN_TRANSIT,
    STATUS_LIST,
    STATUS_OUT_FOR_DELIVERY,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import { getCurrentDateTimeOnJst } from "~/libs/utils";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let changedStatus;
  /** @type {0 | 1 | 2 | 3}*/
  let changedReturnStatus;
  let changedReturnReason;
  let statusList = [];
  let returnStatusList = [];
  let returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
    ReturnReason.ACCEPT_DENIED,
    ReturnReason.ADDRESS_WRONG,
    ReturnReason.ADDRESS_UNKNOWN,
    ReturnReason.REDELIVERY_LIMIT_EXCEEDED,
    ReturnReason.SHIPMENT_PROBLEM,
  ];

  /**
   * 返品理由の選択肢に表示する再配達試行回数
   * @type {number}
   */
  let displayNumberOfDeliveryAttempts;

  /**
   * 配送ステータス登録のバリデーションエラーを表示するかどうかを示すフラグ
   * @type {boolean}
   */
  let displayStatusError = false;

  /**
   * 返品ステータス・理由登録のバリデーションエラーを表示するかどうかを示すフラグ
   * @type {boolean}
   */
  let displayReturnStatusError = false;

  /**
   * 「返品ステータス登録を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnRegisterChecked = false;

  /**
   * 「返品ステータス解除を行う」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let returnCancelChecked = false;

  /** @type {string} 輸送中日時 */
  let inTransitAt;

  /** @type {string} 保管中日時 */
  let heldInDepotAt;

  /** @type {string} 持出日時 */
  let outForDeliveryAt;

  /** @type {string} 配達完了日時 */
  let deliveredAt;

  /** @type {Date} 本日の日付 */
  const today = getCurrentDateTimeOnJst();

  /**
   * @typedef {import("~/libs/commonTypes").DepotLocation & {centers: Array<{distance: string, isNeary: boolean}>}} ExtraDepotLocation
   */
  /** @type {Array<ExtraDepotLocation>} */
  let locationList = [];
  let selectedLocation;

  // ページの初期化処理（非同期）
  (async () => {
    changedStatus = shipment.status;
    changedReturnStatus = shipment.returnStatus;
    changedReturnReason = shipment.returnReason;

    makeStatusList(shipment.status);
    makeReturnStatusList();

    // 作業場所（配送センター）の取得
    locationList = /** @type {Array<ExtraDepotLocation>} */ (
      await depotLocations.get()
    );
  })();

  function makeStatusList(status) {
    if (userContext.hasContractAdminRole() || userContext.hasTrumpAdminRole()) {
      statusList = STATUS_LIST;
      if (status !== STATUS_OUT_FOR_DELIVERY) {
        statusList = statusList.filter((item) => {
          return item !== STATUS_OUT_FOR_DELIVERY;
        });
      }
    } else if (userContext.hasShippingPartnerAdminRole()) {
      switch (status) {
        case STATUS_CREATED:
          changedStatus = STATUS_CREATED;
          statusList.push(STATUS_CREATED);
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_IN_TRANSIT:
          changedStatus = STATUS_IN_TRANSIT;
          statusList.push(STATUS_IN_TRANSIT);
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_HELD_IN_DEPOT:
          changedStatus = STATUS_HELD_IN_DEPOT;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_OUT_FOR_DELIVERY:
          changedStatus = STATUS_OUT_FOR_DELIVERY;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_OUT_FOR_DELIVERY);
          statusList.push(STATUS_DELIVERED);
          break;
        case STATUS_DELIVERED:
          changedStatus = STATUS_DELIVERED;
          statusList.push(STATUS_HELD_IN_DEPOT);
          statusList.push(STATUS_DELIVERED);
          break;
      }
    } else if (userContext.hasEcAdminRole()) {
      statusList = null;
    }

    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    if (
      !userContext.hasEcAdminRole() &&
      status !== STATUS_CREATED &&
      (inTransitAt
        ? new Date(inTransitAt) < beginningOfMonth
        : heldInDepotAt
          ? new Date(heldInDepotAt) < beginningOfMonth
          : outForDeliveryAt
            ? new Date(outForDeliveryAt) < beginningOfMonth
            : deliveredAt
              ? new Date(deliveredAt) < beginningOfMonth
              : false)
    ) {
      // EC管理者ロール以外、かつ現在のステータスが「出荷待ち」ではない
      // かつ「輸送中日時」「保管中日時」「持出日時」「配達完了日時」の内、値が存在する最も早い段階の日時が先月以前の場合
      statusList = statusList.filter((status) => status !== STATUS_CREATED);
    }
  }

  function makeReturnStatusList() {
    returnStatusList = RETURN_STATUS_LIST;
  }

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (changedStatus != shipment.status && !returnRegisterChecked) {
      // 配送ステータスが変更された場合
      // かつ 「返品ステータスを登録する」がチェックされていない場合
      if (
        changedStatus === STATUS_IN_TRANSIT ||
        changedStatus === STATUS_HELD_IN_DEPOT
      ) {
        // 変更先のステータスが「輸送中」「保管中」の場合
        if (Number.isInteger(selectedLocation)) {
          // 配送センターが選択されていれば、登録に必要な条件が全てそろっていると判定
          isAllConditionsMet = true;
          displayStatusError = false;
        } else {
          isAllConditionsMet = false;
          displayStatusError = true;
        }
      } else {
        // 変更先のステータスが「出荷待ち」「配達完了」の場合
        // 登録に必要な条件が全てそろっていると判定
        isAllConditionsMet = true;
        displayStatusError = false;
      }
    } else {
      displayStatusError = false;
    }

    if (returnRegisterChecked || Number.isInteger(shipment.returnStatus)) {
      // 「返品ステータス登録を行う」にチェックが入っている場合もしくは返品ステータスが登録済の場合
      if (
        changedReturnStatus === RETURN_STATUS_WAITING ||
        changedReturnStatus === RETURN_STATUS_RETURNING
      ) {
        // 返品ステータスが「返品待ち」もしくは「返品中」の場合
        if (
          returnReasonList.includes(changedReturnReason) &&
          Number.isInteger(selectedLocation)
        ) {
          // 返品理由と配送センターが選択されていれば、登録に必要な条件が全てそろっていると判定
          isAllConditionsMet = true;
          displayReturnStatusError = false;
        } else {
          isAllConditionsMet = false;
          displayReturnStatusError = true;
        }
      } else if (
        changedReturnStatus === RETURN_STATUS_REQUESTING ||
        changedReturnStatus === RETURN_STATUS_COMPLETE
      ) {
        // 返品ステータスが「返品要求」もしくは「返品完了」の場合
        if (returnReasonList.includes(changedReturnReason)) {
          // 返品理由が選択されていれば、登録に必要な条件が全てそろっていると判定
          isAllConditionsMet = true;
          displayReturnStatusError = false;
        } else {
          isAllConditionsMet = false;
          displayReturnStatusError = true;
        }
      }
    } else {
      // 「返品ステータス登録を行う」にチェックが入っていない場合はエラーメッセージを表示しない
      displayReturnStatusError = false;
    }

    if (returnCancelChecked) {
      // 「返品ステータス解除を行う」がチェックされている場合、登録ボタンを有効化
      isAllConditionsMet = true;
    }

    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};
    /** @type {Array<"photo" | "returnStatus" | "returnReason">} 初期値対象リスト */
    const initializeFields = [];

    updateShipmentEvent.status = changedStatus;
    if (returnCancelChecked) {
      // 返品ステータスを解除する場合
      initializeFields.push("returnStatus");
      initializeFields.push("returnReason");
      // 配送ステータスは更新対象外のため、更新前の状態をセット
      updateShipmentEvent.status = shipment.status;
    } else if (
      returnRegisterChecked ||
      Number.isInteger(shipment.returnStatus)
    ) {
      // 返品対象として登録する場合もしくは返品ステータスが登録済の場合
      updateShipmentEvent.returnStatus = changedReturnStatus;
      updateShipmentEvent.returnReason = changedReturnReason;

      if (
        changedReturnStatus !== RETURN_STATUS_REQUESTING &&
        shipment.status === STATUS_OUT_FOR_DELIVERY
      ) {
        // 返品ステータスが「返品要求」以外、かつ配送ステータスが「持出中」の場合は配送ステータスを「保管中」に変更
        updateShipmentEvent.status = STATUS_HELD_IN_DEPOT;
      } else {
        // 返品ステータスが「返品要求」、もしくは配送ステータスが「持出中」以外の場合は配送ステータスに更新前の状態をセット
        updateShipmentEvent.status = shipment.status;
      }

      if (
        changedReturnStatus === RETURN_STATUS_WAITING ||
        changedReturnStatus === RETURN_STATUS_RETURNING
      ) {
        // 返品ステータスが「返品待ち」もしくは「返品中」の場合は配送センターをセット
        updateShipmentEvent.locationId = selectedLocation;
      }
    } else if (
      changedStatus === STATUS_IN_TRANSIT ||
      changedStatus === STATUS_HELD_IN_DEPOT
    ) {
      // 配送ステータスが「輸送中」もしくは「保管中」の場合は配送センターをセット
      updateShipmentEvent.locationId = selectedLocation;
    }

    if (initializeFields.length > 0) {
      updateShipmentEvent.initializeFields = initializeFields;
    }

    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "status",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="item" id="status">
  <div class="itemTh">配送／返品ステータス</div>
  <div class="itemTd">
    <div class="itemTdLeft column">
      <label for="statusChange" class="statusChangeLabel">
        <select
          id="statusChange"
          class="selectInput"
          bind:value={changedStatus}
          on:change={onInputChange}
          disabled={Number.isInteger(shipment?.returnStatus) ||
            returnRegisterChecked}
        >
          <option value="" disabled>選択してください</option>
          {#each statusList as status}
            <option value={status}>{$_(`classes.status.${status}`)}</option>
          {/each}
        </select>
      </label>

      {#if changedStatus != STATUS_CREATED && changedStatus != STATUS_DELIVERED && !returnRegisterChecked && !Number.isInteger(shipment?.returnStatus)}
        <!-- IF 変更先のステータスが「配達完了」以外の場合
                かつ 「返品ステータスを登録する」がチェックされていない場合
                かつ 返品ステータスが登録されていない場合 -->
        <div class="inputCenterArea">
          {#if locationList != null}
            <label class="inputCenterLabel">
              <select
                name="inputCenter"
                class="selectInput"
                id="inputCenter"
                bind:value={selectedLocation}
                on:change={onInputChange}
              >
                <option value="" selected disabled
                  >配送センターを選択してください</option
                >
                {#each locationList as { prefecture, centers }}
                  <optgroup label={prefecture}>
                    {#each centers as { id, name }}
                      <option value={id}>{name}</option>
                    {/each}
                  </optgroup>
                {/each}
              </select>
            </label>
          {:else}
            <select
              name="failureCenterFailure"
              class="selectInput"
              id="failureCenterFailure"
              disabled
            >
              <option selected>取得失敗</option>
            </select>
          {/if}
        </div>
      {/if}

      {#if changedStatus - shipment.status < 0 && !returnRegisterChecked}
        <!-- IF 配送ステータスが現在のステータスより前の工程に変更された場合 かつ 「返品ステータスを登録する」がチェックされていない場合 -->
        {#if changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT || changedStatus === STATUS_HELD_IN_DEPOT}
          <!-- IF 変更後のステータスが出荷待ち・輸送中・保管中のいずれかの場合 -->
          <div class="initializationWarning">
            <span class="material-icons .md-18"> warning_amber </span>
            <div>
              <p>
                「{$_(
                  `classes.status.${changedStatus}`,
                )}」に戻す場合、以下項目がクリアされます。
              </p>
              <ul>
                {#if changedStatus === STATUS_CREATED}
                  <!-- IF ステータスを出荷待ちに戻す場合 -->
                  <li>荷受け（日時／場所）</li>
                {/if}
                {#if shipment.status != STATUS_IN_TRANSIT && (changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT)}
                  <!-- IF ステータスを輸送中以外から出荷待ちもしくは輸送中に戻す場合 -->
                  <li>持ち出し（日時／場所）</li>
                  <li>宅配ドライバー名</li>
                {/if}
                {#if changedStatus === STATUS_HELD_IN_DEPOT}
                  <!-- IF ステータスを保管中に戻す場合 -->
                  <li>宅配ドライバー名</li>
                {/if}
                {#if shipment.status === STATUS_DELIVERED && (changedStatus === STATUS_CREATED || changedStatus === STATUS_IN_TRANSIT || changedStatus === STATUS_HELD_IN_DEPOT)}
                  <!-- IF ステータスを配達完了から保管中以前に戻す場合 -->
                  <li>配達完了（日時／場所）</li>
                  <li>受け渡し方法（実績）</li>
                  <li>宅配ボックス番号</li>
                  <li>宅配ボックス暗証番号</li>
                  <li>置き配写真</li>
                  <li>配達票写真</li>
                {/if}
              </ul>
            </div>
          </div>
        {/if}
      {/if}

      {#if displayStatusError}
        <p class="validationError">配送センターを選択してください。</p>
      {/if}

      {#if !Number.isInteger(shipment?.returnStatus)}
        <!-- IF 返品ステータスが未登録の場合 -->
        <div class="returnCheckboxArea">
          <FormField>
            <Checkbox
              bind:checked={returnRegisterChecked}
              on:change={onInputChange}
            />
            <span slot="label">返品ステータスを登録する</span>
          </FormField>
        </div>
      {/if}

      {#if (Number.isInteger(shipment?.returnStatus) && !returnCancelChecked) || (!Number.isInteger(shipment?.returnStatus) && returnRegisterChecked)}
        <!--
            IF 返品ステータスが登録済かつ「返品ステータスの登録を解除する」をチェックしていない、
              もしくは返品ステータスが未登録かつ「返品ステータスを登録する」をチェックしている場合
            -->
        <div class="returnRegisterArea">
          <p class="title">【返品に関する登録情報】</p>

          {#if userContext.hasContractAdminRole() || userContext.hasShippingPartnerAdminRole()}
            {#each returnStatusList as returnStatus}
              <FormField>
                <Radio
                  bind:group={changedReturnStatus}
                  value={returnStatus}
                  on:change={onInputChange}
                />
                <span slot="label">
                  {#if userContext.hasContractAdminRole() || userContext.hasTrumpAdminRole()}
                    {$_(`classes.returnStatus.${returnStatus}`)}
                  {:else}
                    {$_(
                      `classes.returnStatusForDeliveryPartner.${returnStatus}`,
                    )}
                  {/if}
                </span>
              </FormField>
            {/each}
          {/if}

          <Select
            style="width: 485px; margin-top: 15px; background-color: #fff;"
            variant="outlined"
            bind:value={changedReturnReason}
            on:SMUISelect:change={onInputChange}
            required
            list$dense
            list$twoLine
          >
            <Option value="" style="height: 50px;"
              >返品理由を選択してください</Option
            >
            {#each returnReasonList as returnReason}
              <Option value={returnReason} style="height: 50px;">
                <p class="returnReasonItem">
                  {$_(`classes.returnReason.${returnReason}`)}
                  {#if returnReason == ReturnReason.REDELIVERY_LIMIT_EXCEEDED}
                    <strong
                      >(現在{displayNumberOfDeliveryAttempts ?? "0"}回)</strong
                    >
                  {/if}
                  <span class="example">
                    {$_(`classes.returnReasonExample.${returnReason}`)}
                  </span>
                </p>
              </Option>
            {/each}
          </Select>

          {#if changedReturnStatus === RETURN_STATUS_WAITING || changedReturnStatus === RETURN_STATUS_RETURNING}
            <!-- IF 変更後の返品ステータスが返品待ち または 返品中の場合 -->
            <div class="inputCenterArea">
              {#if locationList != null}
                <label class="inputCenterLabel">
                  <select
                    name="inputReturnCenter"
                    class="selectInput"
                    id="inputReturnCenter"
                    bind:value={selectedLocation}
                    on:change={onInputChange}
                  >
                    <option value="" selected disabled
                      >配送センターを選択してください</option
                    >
                    {#each locationList as { prefecture, centers }}
                      <optgroup label={prefecture}>
                        {#each centers as { id, name }}
                          <option value={id}>{name}</option>
                        {/each}
                      </optgroup>
                    {/each}
                  </select>
                </label>
              {:else}
                <select
                  name="failureReturnCenter"
                  class="selectInput"
                  id="failureReturnCenter"
                  disabled
                >
                  <option selected>取得失敗</option>
                </select>
              {/if}
            </div>
          {/if}

          {#if displayReturnStatusError}
            <p class="validationError">未入力の項目があります。</p>
          {/if}
        </div>
      {/if}

      {#if Number.isInteger(shipment?.returnStatus)}
        <div class="returnCheckboxArea">
          <!-- IF 返品ステータスが登録済の場合 -->
          <hr style="margin-bottom: 0;" />
          <FormField>
            <Checkbox
              bind:checked={returnCancelChecked}
              on:change={onInputChange}
            />
            <span slot="label">返品ステータスの登録を解除する</span>
          </FormField>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    :global(.mdc-select .mdc-select__menu) {
      max-height: 180px !important;
    }
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .column {
    flex-direction: column;
  }
  .itemTdLeft {
    width: 400px;
    flex-grow: 1;
    display: flex;
    color: #333;
    font-size: 13px;
    .statusChangeLabel,
    .inputCenterLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 486px;
      &::after {
        position: absolute;
        right: 19px;
        width: 10px;
        height: 7px;
        background-color: #666;
        clip-path: polygon(0 0, 100% 0, 50% 76%);
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        width: 100%;
        height: 2.6em;
        padding: 0.4em 30px 0.4em 1.2em;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }
      select:hover {
        border-color: #333;
      }
    }
    .inputCenterLabel {
      margin-top: 10px;
    }
    .selectInput {
      height: 40px;
    }
    .returnCheckboxArea {
      margin-top: 8px;
    }
    .returnRegisterArea {
      margin-top: 12px;
      .title {
        font-size: 15px;
        font-weight: 500;
      }
      .returnReasonItem {
        line-height: 1.2;
        .example {
          display: inline-block;
          margin-top: 4px;
          font-size: 12px;
          color: var(--mdc-theme-secondary);
        }
      }
      :global(.mdc-form-field) {
        width: 100%;
        height: 30px;
      }
      :global(.mdc-select__menu) {
        height: fit-content;
      }
    }
    .validationError {
      color: red;
      font-weight: bold;
      margin-top: 8px;
    }
    .initializationWarning {
      margin-top: 10px;
      padding: 12px 0 12px 16px;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      line-height: 20px;
      .material-icons {
        font-size: 22px;
        color: #d74141;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin-top: 6px;
      }
      li:before {
        content: "「";
      }
      li::after {
        content: "」";
      }
    }
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .selectInput {
    height: 40px;
  }
</style>
