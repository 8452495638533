<script>
  import Button from "@smui/button";

  import { targetSearchResult } from "~/libs/stores";

  export let record;

  $: record;

  function showDetail() {
    targetSearchResult.set(record);
  }
</script>

<Button
  style="height: 30px; width: 60px;"
  touch
  variant="unelevated"
  on:click={showDetail}>詳細</Button
>
