import { toast as svelteToast } from "@zerodevx/svelte-toast";

/**
 * @typedef {object} MySvelteToastOptions
 * @property {boolean} [popsWhenPageMoved=false] 画面遷移に伴って消去可能なToastか否か
 *
 * @typedef {import("@zerodevx/svelte-toast/stores").SvelteToastOptions & MySvelteToastOptions} ExtraSvelteToastOptions
 */

/** ページ遷移時に削除するToastのキュー @type {Array<number>} */
let popQueueForPageMove = [];

export const toast = Object.freeze({
  /**
   * エラーToastを表示する。
   * @param {string} msg
   * @param {ExtraSvelteToastOptions} [options]
   * @returns {number}
   */
  error(msg, options) {
    options = initOptions(options);
    options.theme["--toastBackground"] = "#bd362f";
    return push(msg, options);
  },

  /**
   * INFO Toastを表示する。
   * @param {string} msg
   * @param {ExtraSvelteToastOptions} [options]
   * @returns {number}
   */
  info(msg, options) {
    options = initOptions(options);
    options.theme["--toastBackground"] = "#369";
    return push(msg, options);
  },

  /**
   * Toastを削除する。
   * @param {(number | {[key: string]: string})} [id]
   * @returns {object}
   */
  pop(id) {
    return svelteToast.pop(id);
  },

  /**
   * 画面遷移に伴って消去可能なフラグが立っているToastを削除する。
   */
  popWithPageMove() {
    const currentPopQueue = popQueueForPageMove;
    popQueueForPageMove = [];
    currentPopQueue.forEach((id) => {
      svelteToast.pop(id);
    });
  },
});

/**
 * @param {ExtraSvelteToastOptions} [options]
 * @returns {ExtraSvelteToastOptions}
 */
function initOptions(options) {
  if (!options) {
    options = {};
  }
  if (!options.theme) {
    options.theme = {};
  }
  return options;
}

/**
 * @param {string} msg
 * @param {ExtraSvelteToastOptions} [options]
 * @returns {number}
 */
function push(msg, options) {
  const toastId = svelteToast.push(msg, options);
  if (options?.popsWhenPageMoved === true) {
    popQueueForPageMove.push(toastId);
  }
  return toastId;
}
