<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import { DROPPLACE_LIST } from "~/libs/constants";
  import { formatStringDate } from "~/libs/utils";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  let dispatch = createEventDispatcher();

  let changedPlace;

  // ページの初期化処理（非同期）
  (async () => {
    changedPlace = shipment.actualPackageDropPlace;
  })();

  /**
   * 登録ボタンを有効化するかどうかを判定する
   * @returns {boolean} 登録ボタンを有効化するかどうか
   */
  function updateRegisterButtonState() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;

    if (shipment.actualPackageDropPlace != changedPlace) {
      // 登録ボタンを有効化
      isAllConditionsMet = true;
    }
    return isAllConditionsMet;
  }

  /**
   * 更新する荷物情報を作成する
   * @returns {Promise<import("~/libs/backendApi").UpdateShipmentEvent>} 更新する荷物情報
   */
  async function createUpdateShipmentEvent() {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {};
    if (changedPlace !== shipment.actualPackageDropPlace) {
      // 実際の配達方法が変更された場合
      updateShipmentEvent.actualPackageDropPlace = changedPlace;
    }
    return updateShipmentEvent;
  }

  /**
   * 入力内容が変更されたとき、親コンポーネントにイベントを発行する。
   */
  async function onInputChange() {
    const updateShipmentEvent = await createUpdateShipmentEvent();
    // イベントを発行
    dispatch("inputChange", {
      updateFieldName: "actualPackageDropPlace",
      isAllConditionsMet: updateRegisterButtonState(),
      updateShipmentEvent: updateShipmentEvent,
    });
  }
</script>

<div class="item">
  <div class="itemTh">実際の配達方法</div>
  <div class="itemTd">
    {#if !shipment.agreementOfDropPlace?.agreedAt}
      <label class="actualPackageDropPlaceLabel">
        <select
          name="actualPackageDropPlace"
          class="selectInput"
          id="actualPackageDropPlace"
          bind:value={changedPlace}
          on:change={onInputChange}
        >
          <option value="" selected disabled>選択してください</option>
          {#each DROPPLACE_LIST as dropplace}
            <option value={dropplace}>
              {$_(`classes.packageDropPlace.${dropplace}`)}
            </option>
          {/each}
        </select>
      </label>
    {:else}
      <div>
        <p>
          {#if Number.isInteger(Number(shipment.agreementOfDropPlace.designatedDropPlace))}{$_(
              `classes.designedDropPlace.${shipment.agreementOfDropPlace.designatedDropPlace}`,
            )}{:else}{shipment.agreementOfDropPlace
              .designatedDropPlace}{/if}への置き配（{formatStringDate(
            shipment.agreementOfDropPlace.agreedAt,
            "yyyy/MM/dd HH:mm",
          )}
          {#if Number.isInteger(Number(shipment.agreementOfDropPlace.contactMethod))}{$_(
              `classes.contactMethod.${shipment.agreementOfDropPlace.contactMethod}`,
            )}{:else}{shipment.agreementOfDropPlace
              .contactMethod}{/if}で確認済み）
        </p>
        <p class="note">
          荷受け人と調整済みの受け渡し方法のため、編集できません。
        </p>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .item {
    width: 650px;
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 120px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
    :global(.mdc-select__selected-text),
    :global(.mdc-deprecated-list-item) {
      font-size: 14px;
    }
  }
  .actualPackageDropPlaceLabel {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 487px;
    &::after {
      position: absolute;
      right: 19px;
      width: 10px;
      height: 7px;
      background-color: #666;
      clip-path: polygon(0 0, 100% 0, 50% 76%);
      content: "";
      pointer-events: none;
    }
    select {
      appearance: none;
      width: 100%;
      height: 2.4em;
      padding: 0.4em 30px 0.4em 1.2em;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #fff;
      color: #333333;
      font-size: 1em;
      cursor: pointer;
    }
    select:hover {
      border-color: #333;
    }
  }
  .selectInput {
    height: 40px;
  }
  .note {
    color: #f00;
    font-size: 12px;
  }
  .note::before {
    content: "※ ";
  }
</style>
