<script>
  import Button from "@smui/button";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import help from "~/assets/images/rakuten_help.png";
  import Info from "~/components/icons/info.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  let helpPageOpen = false;
  let currentPage = "";
  let serverName = "";
  let port = "";
  let id = "";
  let password = "";
  let emailAddress = "";
  let emailDisplayName = "";
  let passwordType = "password";
  let supportReply = false;
  let updateButtonDisabled = true;

  $: if (
    // 全項目入力されている場合に変更ボタンを有効化
    serverName &&
    port &&
    id &&
    password &&
    emailAddress &&
    emailDisplayName
  ) {
    updateButtonDisabled = false;
  } else {
    updateButtonDisabled = true;
  }

  function openRakutenHelpPage() {
    currentPage = "rakuten";
    helpPageOpen = true;
  }

  function openOitocHelpPage() {
    currentPage = "oitoc";
    helpPageOpen = true;
  }

  function closeHelpPage() {
    helpPageOpen = false;
  }

  async function update() {
    try {
      await execRakutenEmailApi();
      toast.info($_("message.rakutenEmailUpdateComplete"));
      updateButtonDisabled = true;
    } catch (error) {
      /** @type {import("~/libs/backendApi").ErrorResponse} */
      const errorResponse = error["errorResponse"];

      if (
        errorResponse?.title == "invalidParam" &&
        errorResponse?.details?.violations
      ) {
        /** @type {Array<{level: string, path: string, message: string}>} */
        const violations = errorResponse.details.violations;
        const outputViolations = violations
          .map((v) => "・" + $_("classes.ecMall." + v.path) + "：" + v.message)
          .join("<br />");

        showErrorToast(
          new HandledError(
            null,
            $_("errors.updateRakutenEmailFailed.message", {
              values: { violations: outputViolations },
            }),
          ),
        );
      } else {
        showErrorToast(error);
      }
    }
  }

  async function execRakutenEmailApi() {
    let body = {
      serverName: serverName,
      port: Number(port),
      id: id,
      password: password,
      emailAddress: emailAddress,
      emailDisplayName: emailDisplayName,
      supportReply: supportReply,
    };
    await backendApi.updateRakutenMallSettings(body);
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error instanceof HandledError) {
      toast.error(error.message);
    } else if (error instanceof HTTPError && error.response?.status == 401) {
      toast.error($_("errors.updateUnauthorized.message"));
    } else if (error instanceof HTTPError && error.response?.status == 403) {
      toast.error($_("errors.updateForbidden.message"));
    } else {
      console.error(error);
      toast.error($_("errors.updateDefaultMessage.message"));
    }
  }

  function pwHandleInput(e) {
    password = e.target.value;
  }

  function clearInput() {
    serverName = "";
    port = "";
    id = "";
    password = "";
    emailAddress = "";
    emailDisplayName = "";
    supportReply = false;
  }
</script>

<div class="wrapper">
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">楽天市場利用設定</h1>
  </div>
  <div class="subArea">
    <p>
      楽天市場の注文を置ToCで発送するためには、楽天RMSの設定情報を本画面で登録いただく必要があります。
    </p>
  </div>
  <div class="settingArea">
    <h2 class="settingTitle">
      楽天あんしんメルアドサービス メールサーバ設定情報
    </h2>
    <div class="annotation">
      <button class="annotationButton" on:click={openRakutenHelpPage}
        ><span class="infoIcon smallIcon"><Info /></span
        >設定内容について</button
      >
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">SMTPサーバ</div>
        <input type="text" class="itemText" bind:value={serverName} />
      </div>
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">ポート番号</div>
        <input type="text" class="itemText" bind:value={port} />
      </div>
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">SMTP AUTH ID</div>
        <input type="text" class="itemText" bind:value={id} />
      </div>
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">SMTP AUTH パスワード</div>
        <input
          type={passwordType}
          class="itemText"
          value={password}
          on:input={pwHandleInput}
        />
        <button
          class="material-icons md-dark"
          on:click={() => {
            passwordType = passwordType == "text" ? "password" : "text";
          }}
        >
          {passwordType == "text" ? "visibility_off" : "visibility"}
        </button>
      </div>
    </div>
    <div style="height: 20px"></div>
    <h2 class="settingTitle">置ToC 追加設定情報</h2>
    <div class="annotation">
      <button class="annotationButton" on:click={openOitocHelpPage}
        ><span class="infoIcon smallIcon"><Info /></span
        >設定内容について</button
      >
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">店舗メールアドレス</div>
        <input type="text" class="itemText" bind:value={emailAddress} />
      </div>
    </div>
    <div class="inquiryCheckArea">
      <input
        type="checkbox"
        id="inquiryCheck"
        bind:checked={supportReply}
      /><label for="inquiryCheck">返信メールでの問い合わせを受け付ける</label>
    </div>
    <div class="itemColumn">
      <div class="item">
        <div class="itemName">店舗メール差出人名</div>
        <input type="text" class="itemText" bind:value={emailDisplayName} />
      </div>
    </div>
  </div>

  <div class="buttonArea">
    <Button
      style="margin-left:-10px; height: 40px; width: 156px;  color:rgba(255, 255, 255, 1); background-color:rgba(180, 208, 241, 1);"
      touch
      variant="unelevated"
      on:click={clearInput}
      >入力内容をクリア
    </Button>
    <Button
      style="margin-left:10px; height: 40px; width: 156px;"
      touch
      variant="unelevated"
      bind:disabled={updateButtonDisabled}
      on:click={loadingProgress.wrapAsync(update)}
      >更&nbsp;&nbsp;新
    </Button>
  </div>
</div>

<!-- ポップアップ -->
<div class="popup" style="display: {helpPageOpen ? 'block' : 'none'}">
  <div class="popup_content_wrap">
    <button class="popup_close_label" on:click={closeHelpPage}>×</button>
    <div class="popup_content">
      <div class="popup_area">
        <div class="popup_title">
          <span class="infoIcon largeIcon"><Info /></span>
          <h3>設定内容について</h3>
        </div>
        {#if currentPage === "rakuten"}
          <h4 class="settingTitle">
            楽天あんしんメルアドサービス メールサーバ設定情報
          </h4>
          <p class="popupText">
            楽天市場の管理画面にログインを行い、「あんしんメルアドサービス
            メールサーバー設定情報」に記載されている情報を<br />
            そのままご入力ください。
          </p>
          <div class="popupListArea">
            <p>あんしんメルアドサービスの確認方法</p>
            <ol>
              <li>
                「<a href="https://glogin.gl.rakuten.co.jp/">R-Login</a
                >」にログインする
              </li>
              <li>「あんしんメルアドサービスメニュー」をクリックする</li>
              <li>「メールサーバ設定情報」をクリックする</li>
            </ol>
          </div>
          <div>
            <img src={help} alt="screen shot" width="100%" />
          </div>
        {/if}
        {#if currentPage === "oitoc"}
          <h4 class="settingTitle">置ToC 追加設定情報</h4>
          <p class="popupText">
            <span>・店舗メールアドレス</span><br />
            置ToCから楽天の購入者様宛にメールを送る際の「送信元メールアドレス」を入力してください。<br
            />
            入力するメールアドレスは、RMSの「注文確認メールアドレス」に登録が必要です。
          </p>
          <div class="popupListArea">
            <p>店舗メールアドレスの確認方法</p>
            <ol>
              <li>RMSにログインする</li>
              <li>「店舗設定」をクリックする</li>
              <li>「6 基本情報設定」をクリックする</li>
              <li>「店舗・企業詳細情報」をクリックする</li>
              <li>「注文確認メール送り先メールアドレス」を確認する</li>
            </ol>
          </div>
          <p class="popupText">
            <span>・返信メールでの問い合わせを受け付ける</span><br />
            購入者様が購入商品の詳細や配送状況などを問い合わせるといった意図で返信を行った場合の対応を選択していただきます。<br
            />
            店舗メールアドレスを送信専用とし、返信を受け付けない場合はチェックをオフに、<br
            />
            店舗メールアドレスにて返信を受け取り、店舗側でサポート対応を行いたい場合はチェックをオンにしてください。
          </p>
          <p class="popupText">
            <span>・店舗メール差出人名</span><br />
            楽天市場の「店舗名」を入力してください。<br />
            こちらでご入力いただいた内容は、受注メールテンプレートに自動で挿入されます。
          </p>
        {/if}
      </div>
    </div>
  </div>
  <label for="popup_close">
    <div class="popup_background" />
  </label>
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }
  .titleArea {
    height: 40px;
    display: flex;
  }
  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }
  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }
  .subArea {
    margin: 10px 0 16px;
  }
  .settingArea {
    width: 700px;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
  }
  .settingTitle {
    height: 30px;
    color: #fff;
    background-color: #064491cb;
    line-height: 2.1;
    text-align: center;
  }
  .annotation {
    margin-left: 556px;
  }
  .annotationButton {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #444;
    background-color: #fff;
    text-decoration: underline;
    border: none;
  }
  .infoIcon {
    display: inline-block;
  }
  .smallIcon {
    width: 20px;
  }
  .largeIcon {
    width: 26px;
  }
  .itemColumn {
    display: flex;
  }
  .item {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 220px;
    margin: 0 10px 8px 0;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
    line-height: 2.5;
  }
  .itemText {
    height: 28px;
    width: 100%;
  }
  .buttonArea {
    min-width: 800px;
    padding: 20px 0 10px 340px;
  }
  .inquiryCheckArea {
    margin-left: 184px;
    margin-bottom: 4px;
  }
  .popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
    display: none;
  }
  .popup_content_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    background-color: #fefefe;
    z-index: calc(var(--z-index-modal) - 1);
    border-radius: 5px;
  }
  .popup_close_label {
    background-color: #777;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    line-height: 1.4;
    text-align: center;
    display: table-cell;
    position: fixed;
    top: -15px;
    right: -2%;
    font-size: 1.4em;
    cursor: pointer;
  }
  .popup_content {
    overflow-y: auto;
    padding: 30px;
    line-height: 1.4;
  }
  .popup_area {
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
  }
  .popup_title {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 auto 10px;
    font-size: 20px;
  }
  .popup_background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .popupText {
    margin: 10px 0;
  }
  .popupText span {
    font-weight: bold;
  }
  .popupListArea {
    margin-left: 40px;
  }
  .popupListArea p {
    font-weight: bold;
  }
  .popupListArea ol {
    padding-left: 20px;
  }
  .itemColumn {
    button {
      position: absolute;
      border: none;
      background: transparent;
      cursor: pointer;
      right: 2px;
      top: 4px;
    }
  }
</style>
