<script>
  /** @type {number} 最終業務日からの経過日数 */
  export let numberOfDays;

  /** @type {boolean} 業務を終了済みかどうか */
  export let isFinished;

  let color;
  let status;

  $: (() => {
    if (numberOfDays === Number.MAX_VALUE) {
      // 勤務実績が存在しない場合
      color = "#5f6368";
      status = "実績なし";
      return;
    } else if (numberOfDays === 0) {
      // 最終勤務が本日の場合
      color = "#3db784";
      status = "本日";
    } else if (numberOfDays === 1) {
      // 最終勤務の開始時間が昨日の場合
      color = "#ffbc1f";
      status = "昨日";
    } else {
      // 最終勤務の開始時間が2日以上前の場合
      color = "#5f6368";
      status = `${numberOfDays}日前`;
    }
  })();
</script>

<p style="color: {color};">
  <span class="material-icons">watch_later</span>{status}
  {#if status === "本日" && isFinished}
    <span class="finishedLabel">業務終了</span>
  {/if}
</p>

<style>
  p {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1px;
  }
  .material-icons {
    font-size: 16px;
  }

  .finishedLabel {
    font-size: 11px;
    color: white;
    background-color: #3db784;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 5px;
  }
</style>
