<script context="module">
  export const isNumber = (value) => typeof value === "number";
</script>

<script>
  import { createEventDispatcher } from "svelte";

  import { daysFromLastWorkListData } from "~/libs/stores";
  import { getDaysFromLastWorkedAt } from "~/pages/Activity/activityUtils";

  const dispatch = createEventDispatcher();

  export let results;
  export let columnId;

  let filterValue = [null, null];
  let selectableValues = [];
  let values = [];

  (() => {
    selectableValues = $daysFromLastWorkListData;
    // 本日と昨日は常に選択可能
    selectableValues.push(0);
    selectableValues.push(1);
    selectableValues = Array.from(new Set($daysFromLastWorkListData)).sort(
      (a, b) => a - b,
    );
    if (filterValue[0] === null) filterValue[0] = 0;
    if (filterValue[1] === null) filterValue[1] = 1;
  })();

  function getDisplayValue(value) {
    return value === Number.MAX_VALUE
      ? "実績なし"
      : value === 0
        ? "本日"
        : value === 1
          ? "昨日"
          : `${value}日前`;
  }

  /**
   * セレクトボックスフィルターを適用する
   */
  function applySelectFilter() {
    values = results.filter((result) => {
      /** @type {number} 最終勤務日から経過した日数 */
      const daysSinceLastWorked = getDaysFromLastWorkedAt(result.workTimes);
      return (
        filterValue[0] <= daysSinceLastWorked &&
        daysSinceLastWorked <= filterValue[1]
      );
    });
    dispatch("filter", { values, columnId });
  }
</script>

<div>
  <select
    class="from"
    bind:value={filterValue[1]}
    on:click|stopPropagation
    on:change={() => {
      applySelectFilter();
    }}
  >
    {#each selectableValues as value}
      <option {value}>{getDisplayValue(value)}</option>
    {/each}
  </select>
  ～
  <select
    class="to"
    bind:value={filterValue[0]}
    on:click|stopPropagation
    on:change={() => {
      applySelectFilter();
    }}
  >
    {#each selectableValues as value}
      <option {value}>{getDisplayValue(value)}</option>
    {/each}
  </select>
</div>

<style>
  div {
    display: flex;
  }

  select {
    font-size: 12px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;

    &.from {
      margin-right: 2px;
    }

    &.to {
      margin-left: 2px;
    }
  }
</style>
