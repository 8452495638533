<script>
  import { createEventDispatcher } from "svelte";

  import depotLocations from "~/libs/depotLocations";

  const dispatch = createEventDispatcher();

  export let results;
  export let columnId;

  let filterValue;
  let values = [];
  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  let centersMap;
  /** @type {Array} センター名のリスト */
  let allLocationSourceNames = [];

  (async () => {
    if (columnId === "locationId" || columnId === "transportSourceId") {
      // 配送センター情報のマップを取得
      centersMap = await depotLocations.getCentersMap();
      // centersMapから全てのセンター名のリストを取得
      allLocationSourceNames = Array.from(new Set(centersMap.values()));
    }
  })();

  /**
   * 2つの配列に重複する要素があるかどうかを返す
   * @param {Array} arr1
   * @param {Array} arr2
   * @returns {boolean}
   */
  function getIsDuplicate(arr1, arr2) {
    return (
      [...arr1, ...arr2].filter(
        (item) => arr1.includes(item) && arr2.includes(item),
      ).length > 0
    );
  }

  /**
   * 入力された条件に合致するセンターのID名を抜き出して配列に格納する
   * @returns {number[]} 条件に合致するセンターのID名のリスト
   */
  function getMatchingCenterIds() {
    let matchingCenterIds = [];
    for (let i = 0; i < allLocationSourceNames.length; i++) {
      if (allLocationSourceNames[i].name.includes(filterValue)) {
        matchingCenterIds.push(allLocationSourceNames[i].id);
      }
    }
    return matchingCenterIds;
  }

  /**
   * テキストフィルターを適用する
   */
  function applyTextFilter() {
    if (!filterValue) {
      values = results;
    } else {
      if (columnId === "locationId") {
        // 条件に合致するセンターのID名を取得
        let matchingCenterIds = getMatchingCenterIds();
        // matchingCenterIdsと一致するセンターIDを持つresultを取得
        values = results.filter((result) => {
          if (result.results?.locationSourceIdList === undefined) {
            return false;
          } else {
            let isDuplicate = getIsDuplicate(
              matchingCenterIds,
              result.results?.locationSourceIdList,
            );
            return isDuplicate;
          }
        });
      } else if (columnId === "transportSourceId") {
        // 条件に合致するセンターのID名を取得
        let matchingCenterIds = getMatchingCenterIds();
        // matchingCenterIdsと一致するセンターIDを持つresultを取得
        values = results.filter((result) => {
          if (result.transportSourceIdList === undefined) {
            return false;
          } else {
            let isDuplicate = getIsDuplicate(
              matchingCenterIds,
              result.transportSourceIdList,
            );
            return isDuplicate;
          }
        });
      } else {
        values = results.filter((result) => {
          return result[columnId]?.includes(filterValue);
        });
      }
    }
    dispatch("filter", { values, columnId });
  }
</script>

<div class="text-filter-area">
  <input
    type="text"
    bind:value={filterValue}
    on:click={(event) => {
      event.stopPropagation();
    }}
    on:input={() => {
      applyTextFilter();
    }}
    placeholder="全て"
  />
  {#if filterValue}
    <p>({values.length.toLocaleString()})</p>
  {/if}
</div>

<style lang="scss">
  .text-filter-area {
    display: flex;
    align-items: center;

    input {
      font-size: 12px;
      height: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
    }

    p {
      font-size: 12px;
      margin-left: 5px;
    }
  }
</style>
