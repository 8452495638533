<script>
  import sleep from "sleep-promise";
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import DeleteTable from "~/pages/Upload/DeleteTable.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** ANK EXPRESSのCompanyID */
  const ANK_COMPANY_ID = import.meta.env.MODE === "production" ? 2002 : 2011;

  const dispatch = createEventDispatcher();

  /**
   * 荷受拠点の配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  export let receiptLocationList;

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  export let shippingReceiptUnitList;

  /** 選択されている切離し拠点のid @type {number} */
  export let selectedReceiptLocationId;

  /**
   * 入力中の「切離し拠点」に合致する出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  let filteredShippingReceiptUnitList = [];

  let displayNotes = false;

  onMount(loadingProgress.wrapAsync(fetchShippingReceiptUnitList));

  $: if (Number.isInteger(selectedReceiptLocationId)) {
    filterShippingReceiptUnitList();

    dispatch("changedSelectedReceiptLocationId", {
      value: selectedReceiptLocationId,
    });
  }

  /**
   * 出荷確定データ一覧をフィルタリングする
   */
  function filterShippingReceiptUnitList() {
    if (
      shippingReceiptUnitList.length > 0 &&
      Number.isInteger(selectedReceiptLocationId)
    ) {
      filteredShippingReceiptUnitList = shippingReceiptUnitList.filter(
        (shippingReceiptUnit) =>
          shippingReceiptUnit.companyId ===
            (userContext.hasEcAdminRole()
              ? userContext.loginUser?.currentCompanyId
              : ANK_COMPANY_ID) &&
          shippingReceiptUnit.receiptLocationId === selectedReceiptLocationId,
      );
    }

    for (const shippingReceiptUnit of filteredShippingReceiptUnitList) {
      for (const item of shippingReceiptUnit.numberOfPackagesByLocation) {
        if (item.received) {
          displayNotes = true;
          break;
        }
      }
    }
  }

  /**
   * 出荷確定データ一覧から削除対象のデータを削除する
   * @param {import("~/libs/backendApi").ShippingReceiptUnit} updatedResult
   */
  async function deletedResultsReplace(updatedResult) {
    let targetIndex;
    for (
      targetIndex = 0;
      targetIndex <= shippingReceiptUnitList.length;
      targetIndex++
    ) {
      if (
        shippingReceiptUnitList[targetIndex].shippingReceiptUnitId ===
        updatedResult.shippingReceiptUnitId
      ) {
        break;
      }
    }
    shippingReceiptUnitList.splice(targetIndex, 1);
    shippingReceiptUnitList = shippingReceiptUnitList;

    dispatch("deletedShippingReceiptUnit");

    filterShippingReceiptUnitList();
  }

  /**
   * 最新の荷受け一覧情報を取得し、画面に反映する
   */
  async function fetchShippingReceiptUnitList() {
    try {
      // 画面のちらつき改善のために待ち時間を作る
      await sleep(200);
      // 荷受け一覧情報の取得
      shippingReceiptUnitList =
        (await backendApi.getShippingReceiptUnitList()) ?? [];
      dispatch("changedShippingReceiptUnitList", {
        value: shippingReceiptUnitList,
      });

      filterShippingReceiptUnitList();
    } catch (error) {
      // エラーメッセージのみ表示して継続
      console.warn(error);
      toast.error($_("errors.failedGetRegisteredShippingReceiptUnit.message"));
    }
  }
</script>

<div class="businessArea">
  <div class="selectArea">
    <div class="displayConditionArea">
      <div class="conditionWrapper">
        <div class="reloadWrapper">
          <button
            class="reloadButton"
            on:click={() => {
              loadingProgress.wrapAsync(async () => {
                await fetchShippingReceiptUnitList();
              })();
            }}
          >
            <p class="material-icons md-24">refresh</p>
          </button>
          <div class="noteOnReload">
            <span class="material-icons">refresh</span>ボタンを押すと<br />
            <span class="blank" />最新の登録情報へ更新されます
          </div>
        </div>
        <div class="condition">
          <div class="conditionName">切離し拠点</div>
          <div class="conditionData">
            <select
              id="separatorCenter"
              class="inputSeparationCenter"
              bind:value={selectedReceiptLocationId}
            >
              <option value="" selected disabled>選択してください</option>
              {#each receiptLocationList as { prefecture, centers }}
                <optgroup label={prefecture}>
                  {#each centers as { id, name }}
                    <option value={id}>{name}</option>
                  {/each}
                </optgroup>
              {/each}
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="deleteTargetArea">
      <DeleteTable
        results={filteredShippingReceiptUnitList}
        {deletedResultsReplace}
      />
      {#if displayNotes}
        <ul class="notes">
          <li>
            削除ボタンが表示されない情報は、一部の荷物が荷受け済みのため削除できません。
          </li>
        </ul>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .businessArea {
    .selectArea {
      width: 758px;
      height: fit-content;
      background-color: white;
      border: 1.5px solid #bdbdbdcb;
      border-radius: 0px 5px 5px 5px;
      padding: 10px 15px;
      display: columns;

      .displayConditionArea {
        margin-top: 5px;

        .conditionWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .reloadWrapper {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 4px;

            .reloadButton {
              background-color: rgba(6, 68, 145, 0.796);
              border: 1px solid rgb(107, 107, 107);
              border-radius: 5px;
              width: 36px;
              height: 36px;
              cursor: pointer;
              margin-bottom: 2px;
              padding: 5px 6px 1px;
              color: white;
            }

            .reloadButton:hover {
              background-color: rgba(6, 68, 145, 0.9);
            }

            .noteOnReload {
              font-size: 12px;
              line-height: 1.1;

              .material-icons {
                font-size: 18px;
                vertical-align: middle;
                margin-right: -3px;
              }

              .blank {
                margin-right: 2px;
              }
            }
          }

          .condition {
            display: flex;
            align-items: center;
            justify-content: end;
          }

          .conditionName {
            margin: 1px 3px 1px 10px;
            width: 70px;
            text-align: right;
            color: #242424;
            font-size: smaller;
            font-weight: 900;
          }

          .conditionData {
            .inputSeparationCenter {
              width: 282px;
              margin: 4px 2px;
              height: 35px;
            }
          }
        }
      }
    }

    .deleteTargetArea {
      margin-top: 8px;

      .notes {
        font-size: 0.8em;
        color: #ee3026;
        margin-top: 10px;

        li {
          list-style: none;
          margin-top: 3px;
          text-indent: -1.3em;
          padding-left: 1.3em;
        }
        li::before {
          content: "※";
          margin-right: 3px;
        }
      }
    }
  }
</style>
