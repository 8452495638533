/**
 * 検索結果をソートした時に矢印アイコンの表示を切り替える
 * @param {object} column クリックされた項目
 * @param {object | null} sortedColumn 現在ソート対象になっている項目
 * @param {"asc" | "desc"} sortOrder 検索結果の並び順が昇順か降順か
 * @returns {[ "asc" | "desc", object | null ]}
 */
export function toggleSortIcon(column, sortedColumn, sortOrder) {
  if (sortedColumn === column) {
    sortOrder = sortOrder === "asc" ? "desc" : "asc";
  } else {
    sortOrder = "asc";
  }
  return [sortOrder, column];
}

/**
 * 検索結果を指定された項目の昇順／降順にソートする
 * @param {Array<object>} data
 * @param {object} column
 * @param {"asc" | "desc"} order
 * @returns {Array<object>}
 */
export function sortData(data, column, order) {
  return data.sort((a, b) => {
    const aValue = column.accessor(a);
    const bValue = column.accessor(b);
    if (aValue < bValue) return order === "asc" ? -1 : 1;
    if (aValue > bValue) return order === "asc" ? 1 : -1;
    return 0;
  });
}
