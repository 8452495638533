<script>
  import Button from "@smui/button";
  import { differenceInDays, startOfDay } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import SelectFilter from "~/components/SelectFilter.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_APP, CONTEXT_KEY_USER } from "~/libs/constants";
  import desiredDateTime from "~/libs/desiredDateTime";
  import { sortData, toggleSortIcon } from "~/libs/tableUtils";
  import {
    displayPostcodeFormat,
    formatStringDate,
    formatUtcToJst,
    getCurrentDateTimeOnJst,
  } from "~/libs/utils";
  import SearchResultDetailButton from "~/pages/Search/SearchResultDetailButton.svelte";
  import SearchResultTableStatus from "~/pages/Search/SearchResultTableStatus.svelte";
  import SearchResultTableStatusIcon from "~/pages/Search/SearchResultTableStatusIcon.svelte";

  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} */
  export let results;

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  /** @type {number} */
  export let filterResultsNum;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let columns = [
    { header: "", id: "statusIcon", accessor: (item) => item },
    { header: "", id: "detailButton", accessor: (item) => item },
    {
      header: "送り状番号",
      id: "trackingNumber",
      accessor: (item) => item.trackingNumber ?? "",
    },
    {
      header: "配送／返品ステータス",
      id: "status",
      accessor: (item) => item.shippingAndReturnStatus ?? "",
    },
  ];

  if (!userContext.hasEcAdminRole()) {
    columns.push({
      header: "EC事業者名",
      id: "ecCompanyName",
      accessor: (item) => item.customerName ?? "",
    });
  }
  columns.push(
    {
      header: "注文番号",
      id: "customerOrderId",
      accessor: (item) => item.customerOrderId ?? "",
    },
    {
      header: "届け先郵便番号",
      id: "receiverPostcode",
      accessor: (item) => displayPostcodeFormat(item.receiverPostcode) ?? "",
    },
    {
      header: "届け先住所1",
      id: "receiverAddress1",
      accessor: (item) => item.receiverAddress1 ?? "",
    },
    {
      header: "届け先住所2",
      id: "receiverAddress2",
      accessor: (item) => item.receiverAddress2 ?? "",
    },
    {
      header: "届け先住所（訂正後）",
      id: "correctedReceiverAddress",
      accessor: (item) => item.correctedReceiverAddress ?? "",
    },
    {
      header: "届け先名",
      id: "receiverName",
      accessor: (item) => item.receiverName ?? "",
    },
    {
      header: "個数",
      id: "quantity",
      accessor: (item) => item.numberOfPackages ?? "1",
    },
    {
      header: "発行日時",
      id: "releasedAt",
      accessor: (item) =>
        formatUtcToJst(item.releasedAt, "yyyy/MM/dd(E) HH:mm", {
          locale: localeJa,
        }) ?? "",
    },
  );
  if (!userContext.hasEcAdminRole()) {
    columns.push(
      {
        header: "荷受け登録場所",
        id: "relayLocationId",
        accessor: (item) =>
          Number.isInteger(item.inTransitLocationId)
            ? (centersMap.get(item.inTransitLocationId)?.name ?? "該当なし")
            : "未登録",
      },
      {
        header: "配達希望日時",
        id: "desiredDate",
        accessor: (
          /** @type {import("~/libs/backendApi").SearchedShipment} */ item,
        ) => {
          const dateAndTimeFrame = desiredDateTime
            .resolve(item)
            .find(
              (e) => e.timeFrame !== desiredDateTime.UNRECEIVABLE_TIME_SLOT,
            );
          if (dateAndTimeFrame) {
            // 日付部分のフォーマット
            let formattedDate = "日付指定なし";
            if (dateAndTimeFrame.date) {
              formattedDate = formatStringDate(
                dateAndTimeFrame.date,
                "yyyy/MM/dd(E)",
                { locale: localeJa },
              );
            }
            // 時間部分のフォーマット
            let formattedTime = "時間指定なし";
            if (/^\d{4}$/.test(dateAndTimeFrame.timeFrame)) {
              const timeRangeBegin = `${dateAndTimeFrame.timeFrame.substring(0, 2)}:00`;
              const timeRangeEnd = `${dateAndTimeFrame.timeFrame.substring(2)}:00`;
              formattedTime = `${timeRangeBegin}〜${timeRangeEnd != "00:00" ? timeRangeEnd : ""}`;
            }
            return `${formattedDate} ${formattedTime}`;
          } else {
            if (item.redeliveryContext?.redeliveryDatetimeSpecMethod == null) {
              return "日時指定なし";
            } else {
              return "設定依頼中";
            }
          }
        },
      },
      {
        header: "集荷日時（経過日数）",
        id: "inTransitAt",
        accessor: (item) => item.inTransitAt ?? "未登録",
      },
      {
        header: "配達試行回数",
        id: "numberOfDeliveryAttempts",
        accessor: (item) => item.numberOfDeliveryAttempts ?? "0",
      },
      {
        header: "代引金額",
        id: "cashOnDeliveryAmount",
        accessor: (item) => item.cashOnDeliveryAmount ?? 0,
      },
      {
        header: "宅配ドライバー名",
        id: "driverDisplayName",
        accessor: (item) => item.driverDisplayName ?? "未登録",
      },
    );
  }
  if (userContext.hasEcAdminRole()) {
    columns.push({
      header: "代引金額",
      id: "cashOnDeliveryAmount",
      accessor: (item) => item.cashOnDeliveryAmount ?? 0,
    });
  }
  if (userContext.hasShippingPartnerAdminRole()) {
    columns.push({
      header: "宅配事業者管理者専用メモ欄",
      id: "shippingPartnerInternalMessage",
      accessor: (item) => item.shippingPartnerInternalMessage ?? "",
    });
  }

  /** @type {number} ページの通し番号 */
  let pageIndex = 0;
  /** @type {number} 1ページに表示する検索結果の数 */
  let pageSize = 1000;
  /** @type {object | null} */
  let sortedColumn = null;
  /** @type {"asc" | "desc"} 検索結果の並び順が昇順か降順か */
  let sortOrder = "asc";

  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} フィルタリングされた検索結果 */
  $: filteredData = results;
  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} フィルタリング→ソートされた検索結果 */
  $: sortedData = sortedColumn
    ? sortData(filteredData, sortedColumn, sortOrder)
    : filteredData;
  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} フィルタリング→ソート→ページングされた検索結果(最終的に画面に表示する) */
  $: paginatedData = paginateData(sortedData, pageIndex, pageSize);

  /** @type {boolean} 列フィルターの表示状態 */
  let columnsFilterIsOpen = false;

  /** @type {Array<string>} 表示／非表示制御が可能なカラムIDの配列 */
  const ids = columns.map((column) => column.id);

  /** @type {Array<string>} 非表示のカラムIDを格納する配列 */
  let filteredColumnsForSearch = appContext.filteredColumnsForSearch ?? [];

  $: filterResultsNum = filteredData.length;

  /** @type {Record<string, boolean>} 列フィルターのcheckboxにバインドするためのオブジェクト */
  let checkedValues = {};

  /** フィルタリングする前の検索結果 */
  let originalResults = new Set(results);
  /** 配送／返品ステータスでフィルタリングした結果 */
  let filteredStatus;
  /** 宅配ドライバー名でフィルタリングした結果 */
  let filteredDriverDisplayName;
  /** EC事業者名でフィルタリングした結果 */
  let filteredCompanyName;
  /** 届け先住所1でフィルタリングした結果 */
  let filteredAddress1;
  /** 届け先住所（訂正後）でフィルタリングした結果 */
  let filteredCorrectedAddress;
  /** 宅配事業者管理者専用メモ欄でフィルタリングした結果 */
  let filteredInternalMessage;

  /**
   * 荷受けからの経過日数を算出する
   * @param {string} inputDay
   * @returns {number}
   */
  function calculateDaysFromReleasedAt(inputDay) {
    const now = getCurrentDateTimeOnJst();
    const inTransitAt = new Date(
      formatStringDate(inputDay, "yyyy/MM/dd HH:mm"),
    );
    const startOfNow = startOfDay(now);
    const startOfInTransitAt = startOfDay(inTransitAt);
    const daysPassed = differenceInDays(startOfNow, startOfInTransitAt);
    return daysPassed;
  }

  /**
   * 選択肢もしくはテキストによるフィルタリング機能を適用する
   * @param {CustomEvent} event
   */
  function applyFilter(event) {
    if (event.detail.columnId === "status") {
      filteredStatus = new Set(event.detail.values);
    } else if (event.detail.columnId === "driverDisplayName") {
      filteredDriverDisplayName = new Set(event.detail.values);
    } else if (event.detail.columnId === "companyName") {
      filteredCompanyName = new Set(event.detail.values);
    } else if (event.detail.columnId === "receiverAddress1") {
      filteredAddress1 = new Set(event.detail.values);
    } else if (event.detail.columnId === "correctedReceiverAddress") {
      filteredCorrectedAddress = new Set(event.detail.values);
    } else if (event.detail.columnId === "shippingPartnerInternalMessage") {
      filteredInternalMessage = new Set(event.detail.values);
    }

    filteredData = [...originalResults].filter((item) => {
      let judge = [];
      if (filteredStatus !== undefined) {
        judge.push(filteredStatus.has(item));
      }
      if (filteredDriverDisplayName !== undefined) {
        judge.push(filteredDriverDisplayName.has(item));
      }
      if (filteredCompanyName !== undefined) {
        judge.push(filteredCompanyName.has(item));
      }
      if (filteredAddress1 !== undefined) {
        judge.push(filteredAddress1.has(item));
      }
      if (filteredCorrectedAddress !== undefined) {
        judge.push(filteredCorrectedAddress.has(item));
      }
      if (filteredInternalMessage !== undefined) {
        judge.push(filteredInternalMessage.has(item));
      }
      return judge.every((j) => j);
    });
  }

  /**
   * 検索結果をページングする
   * @param {Array<object>} data
   * @param {number} pageIndex
   * @param {number} pageSize
   * @returns {Array<object>}
   */
  function paginateData(data, pageIndex, pageSize) {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return data.slice(start, end);
  }

  /** @type {Map<string, string>} カラムの幅 */
  const fixedColumnWidth = new Map([
    ["statusIcon", "34px"],
    ["detailButton", "64px"],
    ["trackingNumber", "90px"],
    ["status", "250px"],
    ["ecCompanyName", "80px"],
    ["customerOrderId", "120px"],
    ["receiverPostcode", "100px"],
    ["receiverAddress1", "270px"],
    ["receiverAddress2", "270px"],
    ["correctedReceiverAddress", "270px"],
    ["receiverName", "100px"],
    ["quantity", "40px"],
    ["releasedAt", "130px"],
    ["relayLocationId", "200px"],
    ["desiredDate", "180px"],
    ["inTransitAt", "220px"],
    ["numberOfDeliveryAttempts", "80px"],
    ["cashOnDeliveryAmount", "60px"],
    ["driverDisplayName", "150px"],
    ["shippingPartnerInternalMessage", "200px"],
  ]);

  /**
   * カラムの幅を取得する
   * @param {string} id
   * @returns {string}
   */
  function getColumnWidthStyle(id) {
    return `width: ${fixedColumnWidth.get(id)}`;
  }

  /** @type {Array<string>} 表示・非表示制御が可能なカラムIDの配列 */
  const displayControlIds = [...ids].filter(
    (id) => id !== "statusIcon" && id !== "detailButton",
  );

  /** @type {Map<string, boolean>} 表示カラムの制御用Map */
  const displayControlHideForId = (() => {
    let hideForId = new Map();
    displayControlIds.forEach((id) => {
      hideForId.set(id, true);
    });
    return hideForId;
  })();

  /**
   * 表示カラムの非表示・表示を切り替え + Contextに状態を保持
   * @param {string} id
   * @param {boolean} willStore
   */
  function changeHiddenColumns(id, willStore) {
    displayControlHideForId.set(id, !displayControlHideForId.get(id));

    if (willStore) {
      if (!displayControlHideForId.get(id)) {
        filteredColumnsForSearch.push(id);
      } else {
        filteredColumnsForSearch = filteredColumnsForSearch.filter(
          (columnId) => columnId !== id,
        );
      }
      appContext.filteredColumnsForSearch = filteredColumnsForSearch;
      appContext.store();
    }
  }

  onMount(() => {
    if (filteredColumnsForSearch.length > 0) {
      // appContextに表示カラム設定がある場合は反映
      filteredColumnsForSearch.forEach((columnId) => {
        // 指定したカラムIDについて非表示・表示を切り替え (マウント時はContextに保存しない)
        changeHiddenColumns(columnId, false);
      });
    }
    // 画面上に表示カラム設定を反映
    for (let [id, value] of displayControlHideForId.entries()) {
      checkedValues[id] = value;
    }
  });
</script>

{#if filteredData.length > pageSize}
  <div class="paginationArea">
    <div class="pageSelect">
      ページ
      <select bind:value={pageIndex}>
        {#each Array.from({ length: Math.ceil(filteredData.length / pageSize) }, (_, i) => i) as page}
          <option value={page}>{page + 1}</option>
        {/each}
      </select>
      / {Math.ceil(filteredData.length / pageSize)}
    </div>
    <Button
      variant="unelevated"
      on:click={() => pageIndex--}
      disabled={pageIndex === 0}
    >
      <span class="material-icons">chevron_left</span>
    </Button>
    <Button
      variant="unelevated"
      on:click={() => pageIndex++}
      disabled={pageIndex >= Math.ceil(filteredData.length / pageSize) - 1}
    >
      <span class="material-icons">chevron_right</span>
    </Button>
  </div>
{/if}

<div class="mdc-data-table" class:hasPagination={paginatedData.length > 0}>
  <div
    class="mdc-data-table__table-container"
    class:openColumnsFilterHeight={columnsFilterIsOpen}
  >
    <table class="mdc-data-table__table">
      <thead>
        <tr class="mdc-data-table__header-row">
          {#each columns as column}
            {#if checkedValues[column.id] !== false}
              <th
                class="mdc-data-table__header-cell"
                style={column.id === "statusIcon" ||
                column.id === "detailButton"
                  ? ""
                  : "cursor: pointer;"}
                on:click={() => {
                  [sortOrder, sortedColumn] = toggleSortIcon(
                    column,
                    sortedColumn,
                    sortOrder,
                  );
                }}
              >
                <div class="th-item" style={getColumnWidthStyle(column.id)}>
                  {#if column.id !== "statusIcon" && column.id !== "detailButton"}
                    {column.header}
                    {#if sortedColumn?.id === column.id}
                      <span class="material-icons"
                        >{sortOrder === "asc"
                          ? "arrow_upward"
                          : "arrow_downward"}</span
                      >
                    {/if}
                  {/if}
                </div>
                {#if column.id === "statusIcon"}
                  <div class="columnsFilter">
                    <button
                      class="columnsFilterButton"
                      on:click={() => {
                        columnsFilterIsOpen = !columnsFilterIsOpen;
                      }}
                    >
                      <span class="material-icons md-18">filter_alt</span
                      >列フィルター<span
                        class="material-icons md-18"
                        class:isOpen={columnsFilterIsOpen}
                      >
                        expand_more
                      </span>
                    </button>
                    {#if columnsFilterIsOpen}
                      <div class="columnsFilterMenu">
                        {#each displayControlIds as id}
                          <div class="columnsFilterMenuItem">
                            <input
                              id="hide-{id}"
                              type="checkbox"
                              bind:checked={checkedValues[id]}
                              on:change={() => {
                                changeHiddenColumns(id, true);
                              }}
                            />
                            <label for="hide-{id}"
                              >{$_(`classes.columns.${id}`)}</label
                            >
                          </div>
                        {/each}
                      </div>
                    {/if}
                  </div>
                {:else if column.id !== "detailButton"}
                  {#if column.id === "status" || column.id === "driverDisplayName" || column.id === "companyName"}
                    <!-- 選択肢によるフィルタリング機能 -->
                    <div class="filter-area">
                      <SelectFilter
                        {results}
                        columnId={column.id}
                        on:filter={applyFilter}
                      />
                    </div>
                  {/if}
                  {#if column.id === "receiverAddress1" || column.id === "correctedReceiverAddress" || column.id === "shippingPartnerInternalMessage"}
                    <!-- テキストによるフィルタリング機能 -->
                    <div class="filter-area">
                      <TextFilter
                        {results}
                        columnId={column.id}
                        on:filter={applyFilter}
                      />
                    </div>
                  {/if}
                {/if}
              </th>
            {:else}
              <th class="mdc-data-table__header-cell" style="display: none;" />
            {/if}
          {/each}
        </tr>
      </thead>
      <tbody class="mdc-data-table__content">
        {#if paginatedData.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note" colspan="20">
              該当するデータがありません
            </td>
          </tr>
        {:else}
          {#each paginatedData as row}
            <tr class="mdc-data-table__row">
              {#each columns as column}
                {#if checkedValues[column.id] !== false}
                  <td
                    class="mdc-data-table__cell"
                    class:numericalValue={column.id === "inTransitAt" ||
                      column.id === "numberOfDeliveryAttempts" ||
                      column.id === "cashOnDeliveryAmount" ||
                      column.id === "quantity"}
                  >
                    {#if column.id === "statusIcon"}
                      <SearchResultTableStatusIcon
                        record={column.accessor(row)}
                      />
                    {:else if column.id === "detailButton"}
                      <SearchResultDetailButton record={column.accessor(row)} />
                    {:else if column.id === "status"}
                      <SearchResultTableStatus
                        status={column.accessor(row)}
                        hasShippingPartnerAdminRole={userContext.hasShippingPartnerAdminRole()}
                      />
                    {:else if column.id === "quantity"}
                      {`${column.accessor(row)}個`}
                    {:else if column.id === "inTransitAt"}
                      {column.accessor(row) !== "未登録"
                        ? `${formatStringDate(
                            column.accessor(row),
                            "yyyy/MM/dd(E) HH:mm",
                            { locale: localeJa },
                          )} (${calculateDaysFromReleasedAt(column.accessor(row))}日経過)`
                        : column.accessor(row)}
                    {:else if column.id === "numberOfDeliveryAttempts"}
                      {`${column.accessor(row)}回`}
                    {:else if column.id === "cashOnDeliveryAmount"}
                      {column.accessor(row) !== "0"
                        ? `${Number(column.accessor(row)).toLocaleString()}円`
                        : ""}
                    {:else}
                      {column.accessor(row)}
                    {/if}
                  </td>
                {:else}
                  <td class="mdc-data-table__cell" style="display: none;" />
                {/if}
              {/each}
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .paginationArea {
    display: flex;
    margin: 0 0 5px 10px;

    .pageSelect {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-right: 15px;

      :global(.mdc-select__anchor) {
        padding: 0 5px;
        width: 50px;
        min-width: 40px;
        height: 25px;
        margin: 0 5px;
      }

      :global(.mdc-select__dropdown-icon) {
        margin: 0 -5px 0 0;
      }

      :global(.mdc-select__selected-text) {
        font-size: 13px;
      }

      :global(.mdc-menu) {
        min-width: 0;
      }

      :global(.mdc-deprecated-list-item) {
        height: 25px;
        font-size: 13px;
      }
    }

    :global(.mdc-button) {
      height: 25px;
      margin-right: 5px;
    }
  }
  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 220px);
    overflow-x: auto;
    overflow-y: visible;

    &.hasPagination {
      max-height: calc(100vh - 250px);
    }

    .columnsFilter {
      position: absolute;
      top: 15px;
      left: 20px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;

      .columnsFilterButton {
        font-size: 11px;
        color: black;
        display: flex;
        padding: 4px 0 4px 2px;
        background-color: rgba(180, 208, 241, 0.796);
        border: none;
        border-radius: 5px;
        vertical-align: middle;
        cursor: pointer;

        .isOpen {
          transform: rotate(180deg);
          height: 17px;
        }
      }

      .columnsFilterButton:hover {
        background-color: rgb(143, 180, 225);
      }

      .columnsFilterMenu {
        background-color: white;
        display: column;
        align-items: center;
        padding: 7px 10px;
        font-size: 13px;
        width: 200px;
        border: 1px solid #d3d3d3;
        border-radius: 5px;

        .columnsFilterMenuItem {
          padding: 1px 0;
        }
      }

      :global(.mdc-menu-surface) {
        position: absolute;
        top: 0;
      }
    }

    .mdc-data-table__table-container {
      &.openColumnsFilterHeight {
        min-height: 520px;
      }
    }
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-area);
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
    white-space: normal;
    word-wrap: break-word;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
  .numericalValue {
    text-align: right;
  }

  select {
    text-align: center;
    width: 50px;
    height: 25px;
    margin: 0 5px;
    font-size: 13px;
    border-radius: 5px;
    border: #ccc solid 1px;
    background-color: #fff;
    cursor: pointer;
  }

  .no_data_note {
    text-align: left;
    padding: 10px;
  }
</style>
