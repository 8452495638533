<script>
  import IconButton from "@smui/icon-button";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/backendApi").ShippingReceiptUnit} */
  export let record;
  /** @type {Function} */
  export let deletedResultsReplace;

  /** 全ての荷物が荷受け前かどうか @type {boolean} */
  let isAllBeforeReceived;

  $: if (record) {
    checkAvailableOfDelete();
  }

  (() => {
    checkAvailableOfDelete();
  })();

  /** 対象のデータが削除可能かをチェックする */
  function checkAvailableOfDelete() {
    isAllBeforeReceived = true;
    for (const item of record.numberOfPackagesByLocation) {
      if (item.received) {
        isAllBeforeReceived = false;
        break;
      }
    }
  }

  function showDialog() {
    confirm(
      "以下の登録情報を削除します。よろしいですか？\r\n切離し日：" +
        record.toReceiveOn +
        "\r\n登録回：" +
        record.sequentialNumber,
    ) && deleteTargetRecord();
  }

  /**
   * 登録情報削除APIを実行する。
   * @returns {Promise<void>}
   */
  const execDeletePreUploadApi = loadingProgress.wrapAsync(async () => {
    return await backendApi.deleteShippingReceiptUnit(
      record.shippingReceiptUnitId,
    );
  });

  async function deleteTargetRecord() {
    /** @type {string} */
    let message;
    try {
      await execDeletePreUploadApi();
      toast.info($_("message.deleteComplete"));
    } catch (error) {
      console.error("Error:", error);

      if (error instanceof HandledError) {
        message = error.message;
      } else {
        if (error instanceof HTTPError && error.response?.status == 400) {
          message = $_("errors.updateFailed.message");
        } else if (
          error instanceof HTTPError &&
          error.response?.status == 401
        ) {
          message = $_("errors.updateUnauthorized.message");
        } else if (
          error instanceof HTTPError &&
          error.response?.status == 403
        ) {
          message = $_("errors.updateForbidden.message");
        } else {
          message = $_("errors.updateDefaultMessage.message");
        }
      }
      toast.error(message);
    } finally {
      deletedResultsReplace(record);
    }
  }
</script>

{#if isAllBeforeReceived}
  <IconButton
    class="material-icons"
    size="button"
    style="font-size: 24px; color: #6e6e6e; margin-left: 4px;"
    on:click={showDialog}>delete</IconButton
  >
{/if}
