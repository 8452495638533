<script>
  import Button from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Switch from "@smui/switch";
  import { HTTPError } from "ky";
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    EditPattern,
    RETURN_STATUS_COMPLETE,
    RETURN_STATUS_REQUESTING,
    RecoveryResult,
    ReturnReason,
    STATUS_CREATED,
    STATUS_DELIVERED,
    STATUS_HELD_IN_DEPOT,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import { editSearchResult, searchResultUpdateClose } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/utils";
  import MemoRegistration from "~/pages/Search/Update/MemoRegistration.svelte";
  import SearchResultUpdatePattern from "~/pages/Search/Update/SearchResultUpdatePattern.svelte";

  /** @type {import("~/libs/backendApi").SearchedShipment} */
  export let result;
  /** @type {Function} */
  export let updatedResultsReplace;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  /** @type {boolean} */
  let open;
  /** @type {string} 送り状番号 */
  let displayTracingNumber;
  /** @type {number} 荷物の個数 */
  let displayNumberOfPackages;
  /** @type {string} 荷受人の住所 */
  let displayAddress;
  /** @type {EditPattern} ユーザーによって選択された編集内容 */
  let changedEditPattern;
  /** @type {EditPattern[]} 編集内容の選択肢 */
  let editPatternList = [];
  /** @type {boolean} 登録ボタンの無効化フラグ */
  let registerButtonDisabled = true;
  /** @type {boolean} 通信欄・管理者専用メモ欄を登録するか否か */
  let memoRegisterChecked = false;

  /** @type {import("~/libs/commonTypes").DetailedShipment} 荷物の情報 */
  let shipment;

  /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
  let updateShipmentEvent;

  /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新するメモ情報 */
  let updateMemoEvent;

  onMount(loadingProgress.wrapAsync(openDialog));

  async function openDialog() {
    /** @type {import("~/libs/commonTypes").DetailedShipment} */
    shipment = await backendApi.getDetailedShipment(
      result.trackingNumber,
      true,
    );

    // 1: 画面に表示する荷物情報を設定
    displayNumberOfPackages = shipment.numberOfPackages;
    displayTracingNumber = formatTrackingNumber(shipment.trackingNumber);
    displayAddress = shipment.receiverAddress1 + shipment.receiverAddress2;

    // 2: ロールごとの選択肢出し分け処理を設定
    // ログインユーザーがEC管理者 かつ 返品ステータスが登録されていない かつ 配達完了になっていない
    // かつ 紛失登録されていない場合のみ、配達キャンセル登録を選択できるようにする
    if (
      userContext.hasEcAdminRole() &&
      !Number.isInteger(shipment?.returnStatus) &&
      shipment?.status != STATUS_DELIVERED &&
      !shipment?.lost
    ) {
      editPatternList.push(EditPattern.CANCEL_DELIVERY);
    }

    if (
      (userContext.hasContractAdminRole() ||
        userContext.hasShippingPartnerAdminRole()) &&
      shipment?.returnStatus != RETURN_STATUS_COMPLETE &&
      shipment?.status != STATUS_DELIVERED &&
      !shipment?.lost
    ) {
      // ログインユーザーがコントラクト管理者か宅配パートナー管理者 かつ 返品完了になっていない かつ
      // 配達完了になっていない かつ紛失登録されていない場合のみ、紛失登録を選択できるようにする
      editPatternList.push(EditPattern.LOST);
    }

    if (
      (userContext.hasContractAdminRole() ||
        userContext.hasShippingPartnerAdminRole()) &&
      shipment?.lost
    ) {
      // ログインユーザーがコントラクト管理者か宅配パートナー管理者
      // かつ 紛失登録されている場合のみ、紛失登録解除を選択できるようにする
      editPatternList.push(EditPattern.LOST_CANCEL);
    }

    // ログインユーザーが管理事業者／管理者または宅配事業者／管理者 かつ 返品ステータスが登録されていない かつ
    //  配達完了 かつ 紛失登録されていない かつ 誤送登録されていない場合のみ、誤送登録を選択できるようにする
    if (
      (userContext.hasContractAdminRole() ||
        userContext.hasShippingPartnerAdminRole()) &&
      !Number.isInteger(shipment?.returnStatus) &&
      shipment?.status === STATUS_DELIVERED &&
      !shipment?.lost &&
      !shipment?.misdelivered
    ) {
      editPatternList.push(EditPattern.MISDELIVERY);
    }

    if (
      (userContext.hasContractAdminRole() ||
        userContext.hasShippingPartnerAdminRole()) &&
      !shipment?.lost &&
      shipment?.misdelivered
    ) {
      // ログインユーザーが管理事業者／管理者または宅配事業者／管理者
      // かつ 紛失登録されていない かつ 誤送登録されている場合のみ、誤送登録解除を選択できるようにする
      editPatternList.push(EditPattern.MISDELIVERY_CANCEL);
    }

    if (!userContext.hasEcAdminRole() && !shipment?.returnStatus) {
      // ログインユーザーがEC管理者でない かつ 返品ステータスが登録されていない場合のみ、返品登録を選択できるようにする
      editPatternList.push(EditPattern.RETURN);
    }

    if (!userContext.hasEcAdminRole() && !shipment.notActuallyReceived) {
      // ログインユーザーがEC管理者でない かつ 荷受けできていない荷物として登録されていない場合のみ、
      // 荷受けできていない荷物として登録を選択できるようにする
      editPatternList.push(EditPattern.UNRECEIVED);
    }

    if (!userContext.hasEcAdminRole() && shipment.notActuallyReceived) {
      // ログインユーザーがEC管理者でない かつ 荷受けできていない荷物として登録されている場合のみ、
      // 荷受けできていない荷物として登録解除を選択できるようにする
      editPatternList.push(EditPattern.UNRECEIVED_CANCEL);
    }

    if (!userContext.hasEcAdminRole()) {
      // ログインユーザーがEC管理者でない場合のみ、再配達依頼・置き配写真・配送中の発生事象・その他の登録、
      // 配送ステータスの修正を選択できるようにする
      editPatternList.push(
        EditPattern.REDELIVERY,
        EditPattern.DELIVERY_PHOTO,
        EditPattern.TROUBLES,
        EditPattern.DELIVERY_STATUS,
        EditPattern.OTHER,
      );
    }

    if (userContext.hasShippingPartnerAdminRole()) {
      // ログインユーザーが宅配事業者管理者の場合のみ、訂正住所の登録を選択できるようにする
      editPatternList.push(EditPattern.CORRECT_ADDRESS);
    }

    // editPatternListを番号順に並べる
    editPatternList.sort((a, b) => a - b);

    editPatternList = editPatternList;

    open = true;
  }

  /**
   * 登録ボタンの有効化状態を更新する（メモ欄以外が入力された場合）
   * @param {CustomEvent<{ registerButtonDisabled: boolean }>} event
   */
  function updateRegisterButtonState(event) {
    if (changedEditPattern === EditPattern.OTHER && updateMemoEvent) {
      // その他を選択した場合で、メモ欄の入力内容が変更されている場合は登録ボタンを有効にする
      registerButtonDisabled = false;
    } else {
      // それ以外の場合は受け取ったイベントに従って有効化状態を更新する
      registerButtonDisabled = event.detail.registerButtonDisabled;
    }
  }

  /**
   * 登録ボタンの有効化状態を更新する（メモ欄が入力された場合）
   * @param {CustomEvent<{ registerButtonDisabled: boolean }>} event
   */
  function updateRegisterButtonStateByMemo(event) {
    if (
      (userContext.hasEcAdminRole() && changedEditPattern === undefined) ||
      (changedEditPattern === EditPattern.OTHER && !updateShipmentEvent)
    ) {
      // ログインユーザーがEC管理者かつ編集内容が未選択の場合 または
      // その他を選択した場合で、メモ欄以外の入力内容が変更されていない場合 のみ有効化状態を更新する
      registerButtonDisabled = event.detail.registerButtonDisabled;
    }
  }

  /**
   * 荷物情報の更新イベントを受け取る
   * @param {CustomEvent<{ updateShipmentEvent: import("~/libs/backendApi").UpdateShipmentEvent }>} event
   */
  function updateShipmentEventState(event) {
    updateShipmentEvent = event.detail.updateShipmentEvent;
  }

  /**
   * メモの更新イベントを受け取る
   * @param {CustomEvent<{ updateShipmentEvent: import("~/libs/backendApi").UpdateShipmentEvent }>} event
   */
  function updateMemoEventState(event) {
    updateMemoEvent = event.detail.updateShipmentEvent;
  }

  const closeHandler = async (event) => {
    /** @type {import("~/libs/backendApi").SearchedShipment} */
    let newResult;
    /** @type {string} */
    let message;
    switch (event.detail.action) {
      case "register":
        try {
          await execStatusUpdateApi();

          newResult = Object.assign({}, result);
          // 選択された編集内容に応じて必要な情報の画面表示を更新する
          if (changedEditPattern === EditPattern.CANCEL_DELIVERY) {
            // 配達キャンセル登録の場合
            newResult.returnStatus = 0;
            newResult.returnReason = updateShipmentEvent.returnReason;
          } else if (changedEditPattern === EditPattern.LOST) {
            // 紛失登録の場合
            newResult.lost = true;
          } else if (changedEditPattern === EditPattern.LOST_CANCEL) {
            // 紛失登録解除の場合
            newResult.lost = false;
          } else if (changedEditPattern === EditPattern.MISDELIVERY) {
            // 誤送登録の場合
            if (
              updateShipmentEvent.misdeliveryState === RecoveryResult.FAILURE
            ) {
              newResult.misdelivered = true;
              newResult.misdeliveryState = 0;
            } else if (
              updateShipmentEvent.misdeliveryState ===
              RecoveryResult.SUCCESS_WITHOUT_DAMAGE
            ) {
              newResult.status = STATUS_HELD_IN_DEPOT;
            } else if (
              updateShipmentEvent.misdeliveryState ===
              RecoveryResult.SUCCESS_WITH_DAMAGE
            ) {
              newResult.misdelivered = true;
              newResult.misdeliveryState = 1;
              newResult.returnStatus = RETURN_STATUS_REQUESTING;
              newResult.returnReason = ReturnReason.SHIPMENT_PROBLEM;
            }
          } else if (changedEditPattern === EditPattern.MISDELIVERY_CANCEL) {
            // 誤送登録解除の場合
            newResult.misdelivered = false;
            newResult.misdeliveryState = null;
          } else if (changedEditPattern === EditPattern.RETURN) {
            // 返品登録の場合
            newResult.returnStatus = updateShipmentEvent.returnStatus;
            newResult.returnReason = updateShipmentEvent.returnReason;
          } else if (changedEditPattern === EditPattern.UNRECEIVED) {
            // 荷受け予定の荷物リストに含まれていたが実際は引渡されなかった登録の場合
            newResult.status = STATUS_CREATED;
            newResult.notActuallyReceived = true;
          } else if (changedEditPattern === EditPattern.UNRECEIVED_CANCEL) {
            // 荷受け予定の荷物リストに含まれていたが実際は引渡されなかった登録を解除の場合
            newResult.notActuallyReceived = false;
          } else if (changedEditPattern === EditPattern.REDELIVERY) {
            // 再配達依頼の登録の場合
            newResult.redeliveryContext = updateShipmentEvent.redeliveryContext;
            newResult.specifiedPickupDatetime = undefined; // BEでクリアされるので初期化
          } else if (changedEditPattern === EditPattern.CORRECT_ADDRESS) {
            // 訂正住所の登録の場合
            newResult.correctedReceiverAddress =
              updateShipmentEvent.correctedReceiverAddress;
          } else if (changedEditPattern === EditPattern.DELIVERY_PHOTO) {
            // 配達写真登録の場合
            newResult.status = updateShipmentEvent.status;
          } else if (changedEditPattern === EditPattern.TROUBLES) {
            // 配送中の発生事象の登録の場合
          } else if (
            changedEditPattern === EditPattern.DELIVERY_STATUS ||
            (changedEditPattern === EditPattern.OTHER && updateShipmentEvent)
          ) {
            // 配送ステータスの修正 または その他の登録 かつ メモ欄以外の変更点ありの場合
            if (Number.isInteger(updateShipmentEvent.returnStatus)) {
              // 返品対象として登録する場合もしくは返品ステータスが登録済の場合
              newResult.returnStatus = updateShipmentEvent.returnStatus;
              newResult.returnReason = updateShipmentEvent.returnReason;
              // 配送ステータスは更新対象外のため、セレクトボックスで変更していた場合戻す
              newResult.status = shipment.status;
            } else {
              // 返品対象として登録しない場合
              newResult.status = updateShipmentEvent.status;
              if (Number.isInteger(newResult.returnStatus)) {
                // 返品ステータスが登録済の場合はクリア
                newResult.returnStatus = null;
                newResult.returnReason = null;
              }
            }
            if (updateShipmentEvent.redeliveryContext) {
              // 再配達希望日時が変更された場合
              newResult.redeliveryContext =
                updateShipmentEvent.redeliveryContext;
              newResult.specifiedPickupDatetime = undefined; // BEでクリアされるので初期化
            }
            if (updateShipmentEvent.correctedReceiverAddress) {
              // 訂正住所が変更された場合
              newResult.correctedReceiverAddress =
                updateShipmentEvent.correctedReceiverAddress;
            }
          }
          if (memoRegisterChecked || changedEditPattern === EditPattern.OTHER) {
            // 通信欄・管理者専用メモ欄を登録する場合
            if (updateMemoEvent?.shippingPartnerInternalMessage) {
              // 管理者専用メモ欄が変更された場合
              newResult.shippingPartnerInternalMessage =
                updateMemoEvent.shippingPartnerInternalMessage;
            }
          }
          updatedResultsReplace(newResult);

          toast.info($_("message.updateComplete"));
        } catch (error) {
          if (error instanceof HandledError) {
            message = error.message;
          } else {
            if (error instanceof HTTPError && error.response?.status == 400) {
              console.error(error);
              toast.error($_("errors.updateFailed.message"));
            } else if (
              error instanceof HTTPError &&
              error.response?.status == 401
            ) {
              console.error(error);
              toast.error($_("errors.updateUnauthorized.message"));
            } else if (
              error instanceof HTTPError &&
              error.response?.status == 403
            ) {
              console.error(error);
              toast.error($_("errors.updateForbidden.message"));
            } else {
              logger.error(
                "[SearchResultUpdate] 配送情報更新時にエラーが発生しました",
                {
                  username: userContext.loginUser?.username,
                  trackingNumber: result.trackingNumber,
                },
                error,
              );
              toast.error($_("errors.updateDefaultMessage.message"));
            }
          }
          newResult = null;
        } finally {
          dispatch("message", { text: message });
        }
        break;
      case "cancel":
        break;
    }
    editSearchResult.set(newResult);
    searchResultUpdateClose.set(true);
  };

  /**
   * 配送情報を更新する
   */
  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    const updateStatus = deepMerge(updateShipmentEvent, updateMemoEvent);
    if (!updateStatus.trackingNumber) {
      // 必須条件が未設定の場合は現状のステータスをそのまま追加
      updateStatus.trackingNumber = shipment.trackingNumber;
      updateStatus.status = shipment.status;
      updateStatus.version = shipment.version;
    }
    await backendApi.updateShipment(updateStatus);
  });

  /**
   * 2つの更新データを深いレベルでマージする
   * @param {import("~/libs/backendApi").UpdateShipmentEvent} obj1
   * @param {import("~/libs/backendApi").UpdateShipmentEvent} obj2
   * @returns {import("~/libs/backendApi").UpdateShipmentEvent}
   */
  function deepMerge(obj1, obj2) {
    const merged = { ...obj1 };
    for (const key in obj2) {
      if (typeof obj2[key] === "object" && obj2[key] !== null) {
        if (Array.isArray(obj2[key])) {
          merged[key] = obj2[key];
        } else {
          merged[key] = deepMerge(obj1 ? obj1[key] : {}, obj2[key]);
        }
      } else {
        merged[key] = obj2[key];
      }
    }
    return merged;
  }

  /**
   * 編集内容を選択したとき、登録内容の初期化を行う
   */
  function resetRegistrationState() {
    if (
      changedEditPattern !== EditPattern.LOST &&
      changedEditPattern !== EditPattern.LOST_CANCEL &&
      changedEditPattern !== EditPattern.MISDELIVERY_CANCEL &&
      changedEditPattern !== EditPattern.UNRECEIVED &&
      changedEditPattern !== EditPattern.UNRECEIVED_CANCEL
    ) {
      // 紛失登録、紛失登録解除、誤送登録解除、荷受け予定の荷物リストに含まれていたが実際は引渡されなかった荷物として登録、
      // 荷受け予定の荷物リストに含まれていたが実際は引渡されなかった荷物として登録解除 以外の場合は登録ボタンを無効化する
      registerButtonDisabled = true;
      // 入力された内容をクリアする
      updateShipmentEvent = null;
    }
  }

  /**
   * ダイアログスクロール時にSelectのメニューを非表示にする
   */
  function dialogScrollEvent() {
    for (
      let i = 0;
      i <
      document.getElementsByClassName("mdc-select mdc-select--activated")
        .length;
      i++
    ) {
      let activeSelect = document.getElementsByClassName(
        "mdc-select mdc-select--activated",
      )[i];
      let activeMenu = activeSelect.getElementsByClassName(
        "mdc-select__menu mdc-menu-surface--open",
      )[0];

      activeMenu.classList.remove(
        "mdc-menu-surface--open",
        "mdc-menu-surface--is-open-below",
      );
      activeSelect.classList.remove(
        "mdc-select--activated",
        "mdc-select--focused",
      );
    }
  }
</script>

<div class="dialog">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%;"
  >
    <Title id="detail-dialog-title">配送情報の編集</Title>

    <Content id="detail-dialog-content" on:scroll={dialogScrollEvent}>
      <div class="readOnlyItemArea">
        <div class="item readOnly">
          <div class="itemTh">送り状番号</div>
          <div class="itemTd">
            <div class="itemTdLeft">
              {displayTracingNumber}{#if displayNumberOfPackages > 1}（{displayNumberOfPackages}個）{/if}
            </div>
          </div>
        </div>
        <div class="item readOnly">
          <div class="itemTh">荷受人の住所</div>
          <div class="itemTd">
            <div class="itemTdLeft">
              {displayAddress}
            </div>
          </div>
        </div>
      </div>

      <div class="editPatternArea">
        {#if editPatternList.length > 0}
          <div class="editPatternWrapper">
            <select
              name="editPattern"
              class="selectInput"
              id="editPattern"
              bind:value={changedEditPattern}
              on:change={resetRegistrationState}
            >
              <option value="" selected disabled
                >登録内容を選択してください</option
              >
              {#each editPatternList as editPattern}
                <option value={editPattern}>
                  <p class="editPatternItem">
                    【{$_(`classes.editPattern.${editPattern}`)}】
                    <span class="example">
                      {$_(`classes.editPatternExample.${editPattern}`)}
                    </span>
                  </p></option
                >
              {/each}
            </select>
          </div>
          {#if Number.isInteger(changedEditPattern)}
            <div
              class="editableItemArea"
              style="padding-bottom: {memoRegisterChecked ||
              changedEditPattern === EditPattern.OTHER
                ? '0'
                : '10px'};"
            >
              <!-- 編集したい内容に応じて表示する項目を出し分け -->
              <SearchResultUpdatePattern
                {shipment}
                {changedEditPattern}
                on:enableRegisterButton={updateRegisterButtonState}
                on:updateShipmentEvent={updateShipmentEventState}
              />

              {#if changedEditPattern != EditPattern.OTHER}
                <!-- その他以外を選択した場合は、Switchで通信欄・管理者専用メモ欄の表示を切り替える -->
                <FormField style="margin-top: 10px;">
                  <Switch
                    bind:checked={memoRegisterChecked}
                    on:SMUISwitch:change={() => {
                      if (!memoRegisterChecked) {
                        // 「通信欄・管理者専用メモ欄を登録する」がオフになった場合は入力内容をクリアする
                        updateMemoEvent = null;
                        if (!updateShipmentEvent) {
                          // メモ欄以外の入力内容が変更されていない場合は登録ボタンを無効化する
                          registerButtonDisabled = true;
                        }
                      }
                    }}
                  />
                  <span slot="label"
                    >通信欄{#if userContext.hasShippingPartnerAdminRole()}・管理者専用メモ欄{/if}を編集する</span
                  >
                </FormField>
              {/if}
            </div>
          {/if}
        {:else}
          <p>通信欄のみ編集が可能です。</p>
        {/if}

        {#if memoRegisterChecked || changedEditPattern === EditPattern.OTHER || editPatternList.length === 0}
          <div
            class="editableMemoArea"
            style="background-color: {editPatternList.length > 0
              ? '#fbfbfb'
              : 'white'};"
          >
            {#if shipment}
              <!-- 通信欄・管理者専用メモ欄を編集 -->
              <MemoRegistration
                {shipment}
                {changedEditPattern}
                on:enableRegisterButton={updateRegisterButtonStateByMemo}
                on:updateShipmentEvent={updateMemoEventState}
              />
            {/if}
          </div>
        {/if}
      </div>
    </Content>

    <div class="buttonArea">
      <Actions>
        <Button
          action={"cancel"}
          style="background-color: #D9D9D9; color: #333;">キャンセル</Button
        >
        <Button
          action={"register"}
          touch
          variant="unelevated"
          bind:disabled={registerButtonDisabled}>登録</Button
        >
      </Actions>
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialog {
    :global(.mdc-select--outlined .mdc-select__anchor) {
      width: 300px;
      height: 36px;
    }
    :global(.mdc-button--touch) {
      margin-top: 0;
      margin-bottom: 0;
    }
    :global(.mdc-dialog .mdc-dialog__container) {
      width: 90%;
    }
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: 100%;
      min-width: 300px;
      max-width: 700px;
    }
    :global(.mdc-dialog__title) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 13px;
      padding-bottom: 12px;
    }
    :global(.mdc-dialog__title .mdc-button) {
      background-color: #f90404;
      color: #fff;
      margin-left: auto;
      width: 100px;
    }
    :global(.mdc-dialog__content) {
      padding: 0;
      border-top: 1px solid #ddd;
    }
  }
  .readOnlyItemArea {
    background-color: #fff;
    padding: 15px 20px 0;
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-area);
    width: 635px;
  }
  .item {
    display: flex;
    gap: 10px;
    position: relative;
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    width: 635px;
    &.readOnly {
      padding: 1px 0;
      border-bottom: none;
    }
  }
  .itemTh {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    min-width: 150px;
    line-height: 1.2em;
    background-color: #b4d0f1cb;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .item.readOnly .itemTh {
    background-color: #dbdbdb;
  }
  .itemTd {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  }
  .editPatternArea {
    margin-top: 15px;
    padding: 0 10px;
    .editPatternItem {
      line-height: 1.2;
      .example {
        display: inline-block;
        margin-top: 4px;
        font-size: 12px;
        color: var(--mdc-theme-secondary);
      }
    }
    :global(.mdc-form-field) {
      height: 30px;
    }
  }
  .editPatternArea > p {
    margin-left: 10px;
  }
  .editPatternWrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 646px;
    margin-left: 10px;
    &::after {
      position: absolute;
      right: 19px;
      width: 10px;
      height: 7px;
      background-color: #666;
      clip-path: polygon(0 0, 100% 0, 50% 76%);
      content: "";
      pointer-events: none;
    }
    #editPattern {
      width: 646px;
    }
    select {
      appearance: none;
      width: 100%;
      padding: 0.4em 30px 0.4em 1.2em;
      border: 1px solid #999;
      border-radius: 3px;
      background-color: #fff;
      color: #333333;
      font-size: 1em;
      cursor: pointer;
    }
    select:hover {
      border-color: #333;
    }
  }
  .editableItemArea {
    width: 646px;
    font-size: 14px;
    margin-top: 10px;
    padding: 6px 10px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #f8f8f8;
  }
  .editableMemoArea {
    width: 646px;
    font-size: 14px;
    padding: 0px 10px 6px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #f8f8f8;
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    :global(.mdc-dialog__actions) {
      border-top: none;
    }
    :global(.mdc-form-field) {
      margin-left: 6px;
      margin-right: auto;
    }
  }
</style>
