<script>
  import Button from "@smui/button";
  import { ja as localeJa } from "date-fns/locale";
  import { createEventDispatcher } from "svelte";

  import TextFilter from "~/components/TextFilter.svelte";
  import { sortData, toggleSortIcon } from "~/libs/tableUtils";
  import { formatStringDate } from "~/libs/utils";
  import KnowledgeTrashIcon from "~/pages/Knowledge/KnowledgeTrashIcon.svelte";

  /**
   * @type {Array<import("~/libs/commonTypes").ShippingKnowledge>}
   */
  export let results;
  /** @type {Function} */
  export let deletedResultsReplace;

  const dispatch = createEventDispatcher();

  const columns = [
    { header: "", id: "trashIcon", accessor: (item) => item },
    { header: "", id: "updateButton", accessor: (item) => item },
    {
      header: "住所",
      id: "address",
      accessor: (item) => displayAddressAndType(item.address, item.type) ?? "",
    },
    { header: "共有配達メモ", id: "memo", accessor: (item) => item.memo ?? "" },
    {
      header: "最終更新日時",
      id: "updatedAt",
      accessor: (item) =>
        formatStringDate(item.updatedAt, "yyyy/MM/dd HH:mm", {
          locale: localeJa,
        }) ?? "",
    },
    {
      header: "最終更新者",
      id: "updatedBy",
      accessor: (item) => item.updatedBy ?? "",
    },
  ];

  /**
   * 住所と種類をあわせて表示する。
   * @param {string} address 住所
   * @param {number} type 種類
   * @returns {string} 住所と種類
   */
  function displayAddressAndType(address, type) {
    let formattedAddressAndType;
    if (type === 0) {
      formattedAddressAndType = address;
    } else if (type === 1) {
      formattedAddressAndType =
        address + "<span style='color: #2b81d6;'>&nbsp;(近隣)</span>";
    }
    return formattedAddressAndType;
  }

  /**
   * 編集ボタンクリック時の処理
   * @param {object} cell
   */
  function handleUpdateButtonClick(cell) {
    const targetResult = cell;
    dispatch("dialogOpen", targetResult);
  }

  $: resultsNum = sortedData.length;
  /** @type {object | null} 現在ソート対象になっている項目 */
  let sortedColumn = null;
  /** @type {"asc" | "desc"} 検索結果の並び順が昇順か降順か */
  let sortOrder = "asc";

  /** @type {Array<object>} フィルタリングされた検索結果 */
  $: filteredData = results;
  /** @type {Array<object>} フィルタリング→ソートされた検索結果 */
  $: sortedData = sortedColumn
    ? sortData(filteredData, sortedColumn, sortOrder)
    : filteredData;

  /**
   * 選択肢もしくはテキストによるフィルタリング機能を適用する
   * @param {CustomEvent} event
   */
  function applyFilter(event) {
    filteredData = event.detail.values;
  }
</script>

<div class="resultHeader">
  <h1 class="resultNumber">全 {resultsNum.toLocaleString()}件</h1>
</div>
<div class="mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table">
      <thead>
        <tr class="mdc-data-table__header-row">
          {#each columns as column}
            <th
              class="mdc-data-table__header-cell"
              style={column.id === "trashIcon" || column.id === "updateButton"
                ? ""
                : "cursor: pointer;"}
              on:click={() => {
                [sortOrder, sortedColumn] = toggleSortIcon(
                  column,
                  sortedColumn,
                  sortOrder,
                );
              }}
            >
              {#if column.id !== "trashIcon" && column.id !== "updateButton"}
                <div class="th-item">
                  {column.header}
                  {#if sortedColumn?.id === column.id}
                    <span class="material-icons"
                      >{sortOrder === "asc"
                        ? "arrow_upward"
                        : "arrow_downward"}</span
                    >
                  {/if}
                </div>
                {#if column.id === "address"}
                  <!-- テキストによるフィルタリング機能 -->
                  <div class="filter-area">
                    <TextFilter
                      {results}
                      columnId={column.id}
                      on:filter={applyFilter}
                    />
                  </div>
                {/if}
              {/if}
            </th>
          {/each}
        </tr>
      </thead>
      <tbody class="mdc-data-table__content">
        {#if sortedData.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note" colspan="6">
              該当するデータがありません。
            </td>
          </tr>
        {:else}
          {#each sortedData as row}
            <tr class="mdc-data-table__row">
              {#each columns as column}
                {#if column.id === "trashIcon"}
                  <td class="mdc-data-table__cell">
                    <KnowledgeTrashIcon
                      record={column.accessor(row)}
                      {deletedResultsReplace}
                    />
                  </td>
                {:else if column.id === "address"}
                  <td class="mdc-data-table__cell address-cell">
                    {@html column.accessor(row)}
                  </td>
                {:else if column.id === "memo"}
                  <td class="mdc-data-table__cell memo-cell">
                    {column.accessor(row)}
                  </td>
                {:else if column.id === "updatedBy"}
                  <td class="mdc-data-table__cell updated-user-cell">
                    {column.accessor(row)}
                  </td>
                {:else if column.id === "updateButton"}
                  <td class="mdc-data-table__cell">
                    <Button
                      style="height: 30px; width: 60px;"
                      touch
                      variant="unelevated"
                      on:click={handleUpdateButtonClick(column.accessor(row))}
                      >編集</Button
                    >
                  </td>
                {:else}
                  <td class="mdc-data-table__cell">
                    {column.accessor(row)}
                  </td>
                {/if}
              {/each}
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .resultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .resultNumber {
    margin: 12px 20px 5px;
  }

  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 236px);
    overflow-x: auto;
    overflow-y: none;
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky-area);
  }

  .memo-cell {
    min-width: 420px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  .address-cell {
    min-width: 340px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  .updated-user-cell {
    min-width: 200px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
</style>
