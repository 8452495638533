<script>
  import SegmentedButton, { Label, Segment } from "@smui/segmented-button";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { createEventDispatcher, getContext, tick } from "svelte";
  import { _ } from "svelte-i18n";
  import * as XLSX from "xlsx";

  import backendApi, { ErrorResponseException } from "~/libs/backendApi";
  import { CONTEXT_KEY_APP, CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import {
    formatStringDate,
    formatTrackingNumber,
    getCurrentDateTimeOnJst,
    getLocationName,
    toHalfWidth,
  } from "~/libs/utils";

  /** 同期処理を可能にしたFileReaderクラス */
  class FileReaderEx extends FileReader {
    constructor() {
      super();
    }

    #readAs(blob, ctx) {
      return new Promise((res, rej) => {
        super.addEventListener("load", ({ target }) => res(target.result));
        super.addEventListener("error", ({ target }) => rej(target.error));
        super[ctx](blob);
      });
    }

    readAsArrayBuffer(blob) {
      return this.#readAs(blob, "readAsArrayBuffer");
    }
  }

  /**
   * 配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  export let locationList;

  /**
   * 荷受拠点の配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  export let receiptLocationList;

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  export let shippingReceiptUnitList;

  /** 選択されている切離し拠点のid @type {number} */
  export let selectedReceiptLocationId;

  /** 登録方法「新規／上書き登録」 */
  const NEW_OR_OVERWRITE = "0";
  /** 登録方法「追加登録」 */
  const ADDITIONAL = "1";
  /** ANK EXPRESSのCompanyID */
  const ANK_COMPANY_ID = import.meta.env.MODE === "production" ? 2002 : 2011;
  /**
   * 登録結果タイプ
   * @enum {0 | 1 | 2 | 3}
   */
  const RegistrationResultType = Object.freeze({
    /** 新規登録 */
    NEW: 0,
    /** 上書き登録 */
    OVERWRITE: 1,
    /** 追加登録 */
    ADD: 2,
    /** 自動新規登録 */
    AUTO_NEW: 3,
  });

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  /** @type {Date} 本日の日付(日本時間) */
  const today = getCurrentDateTimeOnJst();

  /** アップロード可能な出荷データファイルの拡張子 */
  const validFileExtensions = ["xlsx"];

  /** アップロードCSVファイルのヘッダ行有無 @type {boolean} */
  const shippingRreceiptUnitUploadCsvHasHeader =
    userContext.ecSettings?.shippingRreceiptUnitUploadCsvHasHeader ?? true;

  // FIXME: ECマスタを取得できない場合は暫定のハードコードされたデフォルト値を使用しているため変更が必要
  /** アップロードCSVファイルの送り状番号の列番号 @type {number} */
  const shippingReceiptUnitUploadCsvTrackingNumberColumnNumber =
    userContext.ecSettings
      ?.shippingReceiptUnitUploadCsvTrackingNumberColumnNumber ?? 2;

  /**
   * 登録条件
   * @type {{
   *   toReceiveOn: string, // 切離し日
   *   receiptLocationId: number, // 切離し拠点ID
   *   sequentialNumber: number, // 出荷データ登録回
   *   uploadMethod: NEW_OR_OVERWRITE | ADDITIONAL, // 登録手段
   *   uploaderEcId: number // 登録者(CompanyID)
   * }}
   */
  const registrationConditions = {
    toReceiveOn: formatDate(today, "yyyy-MM-dd", {
      locale: localeJa,
    }),
    receiptLocationId: selectedReceiptLocationId,
    sequentialNumber: 1,
    uploadMethod: NEW_OR_OVERWRITE,
    uploaderEcId: userContext.hasEcAdminRole()
      ? userContext.loginUser?.currentCompanyId
      : ANK_COMPANY_ID, // TODO: 2024/10時点ではANK決め打ちとしているが将来的にはECを選択可能とする必要がある
  };
  $: if (shippingReceiptUnitList) {
    filterShippingReceiptUnitList();
  }
  $: if (registrationConditions.toReceiveOn) {
    filterShippingReceiptUnitList();
  }
  $: if (Number.isInteger(selectedReceiptLocationId)) {
    registrationConditions.receiptLocationId = selectedReceiptLocationId;
    filterShippingReceiptUnitList();

    dispatch("changedSelectedReceiptLocationId", {
      value: selectedReceiptLocationId,
    });
  }

  /**
   * 読込み結果情報
   * @type {{
   *   formatErrorList: {index: number, content: string}[], // 形式エラー情報リスト
   *   emptyErrorList: number[], // 空欄エラー行番号リスト
   *   trackingNumberList: string[] // 送り状番号リスト
   * }}
   */
  let readResultInfo;

  /**
   * 登録結果情報
   * @type {{
   *   registrationType: RegistrationResultType, // 登録結果タイプ
   *   registrationConditions: {
   *     toReceiveOn: string, // 切離し日
   *     receiptLocationId: number, // 切離し拠点ID
   *     sequentialNumber: number, // 出荷データ登録回
   *   },
   *   registrationResult: import("~/libs/backendApi").ShippingReceiptUnitResponse // 登録結果response
   * }}
   */
  let registrationResultInfo;

  /**
   * 入力中の「切離し日」と「切離し拠点」の両方に合致する出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  let filteredShippingReceiptUnitList = [];

  (() => {
    filterShippingReceiptUnitList();
  })();

  /** @type {FileList} */
  let files;
  /** @type {File} */
  let readedFile;
  let fileName = "ファイルが選択されていません。";
  let fileType;
  let registButtonDisabled = true;

  $: if (
    registrationConditions &&
    registrationConditions.toReceiveOn &&
    Number.isInteger(registrationConditions.receiptLocationId) &&
    registrationConditions.sequentialNumber &&
    readResultInfo
  ) {
    registButtonDisabled = false;
  } else {
    registButtonDisabled = true;
  }

  $: if (files) {
    if (files.length > 0 && files[0] != readedFile) {
      readedFile = files[0];
      fileName = readedFile.name;
      fileType = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      if (validFileExtensions.includes(fileType) && readedFile.size > 0) {
        readShippingNumberList();
        registButtonDisabled = false;
      } else {
        registButtonDisabled = true;
        alert(
          "所定の形式の出荷データファイル（拡張子は.xlsx）を選択してください。",
        );
      }
    }
  }

  /**
   * 出荷確定データファイルを読み込み、結果をプレビュー表示する
   */
  async function readShippingNumberList() {
    const tmpReadResultInfo = {
      formatErrorList: [],
      emptyErrorList: [],
      trackingNumberList: [],
    };
    const shippingNumberColumn =
      shippingReceiptUnitUploadCsvTrackingNumberColumnNumber - 1;

    const reader = new FileReaderEx();
    reader.onload = async function (e) {
      // @ts-ignore
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let lastEmptyErrorList = [];

      if (json.length > 0) {
        for (const [index, row] of json.entries()) {
          console.log(`Row ${index + 1}:`, row[shippingNumberColumn]);
          if (shippingRreceiptUnitUploadCsvHasHeader && index === 0) {
            // ヘッダ行あり＆1行目の場合はスキップ
            continue;
          }

          // 送り状番号を取得
          const originalTrackingNumber = row[shippingNumberColumn];
          if (!originalTrackingNumber) {
            // 送り状番号が空の場合は空欄エラーリストに追加
            tmpReadResultInfo.emptyErrorList.push(index + 1);
            lastEmptyErrorList.push(index + 1);
            continue;
          }

          // 空白・ハイフン除去＆半角に変換
          const trackingNumber = toHalfWidth(
            originalTrackingNumber.toString().trim().replace(/-/g, ""),
          );
          if (!/^\d{12}$/.test(trackingNumber)) {
            // 送り状番号が12桁の数字でない場合は形式エラーリストに追加
            tmpReadResultInfo.formatErrorList.push({
              index: index + 1,
              content: originalTrackingNumber,
            });
          } else {
            // 送り状番号が正常な場合は送り状番号リストに追加
            tmpReadResultInfo.trackingNumberList.push(trackingNumber);
          }
          lastEmptyErrorList = [];
        }
        if (lastEmptyErrorList.length > 0) {
          // 最後の空欄エラーリストを削除
          tmpReadResultInfo.emptyErrorList =
            tmpReadResultInfo.emptyErrorList.filter(
              (index) => !lastEmptyErrorList.includes(index),
            );
        }
      }

      return true;
    };
    await reader.readAsArrayBuffer(readedFile);
    readResultInfo = tmpReadResultInfo;
  }

  /**
   * 出荷確定データ一覧をフィルタリングする
   */
  function filterShippingReceiptUnitList() {
    filteredShippingReceiptUnitList = [];
    if (
      shippingReceiptUnitList.length > 0 &&
      registrationConditions.toReceiveOn &&
      registrationConditions.receiptLocationId
    ) {
      filteredShippingReceiptUnitList = shippingReceiptUnitList.filter(
        (shippingReceiptUnit) =>
          shippingReceiptUnit.companyId ===
            registrationConditions.uploaderEcId &&
          shippingReceiptUnit.receiptLocationId ===
            registrationConditions.receiptLocationId &&
          shippingReceiptUnit.toReceiveOn ===
            registrationConditions.toReceiveOn,
      );
    }
  }

  /**
   * 出荷確定データを登録する
   */
  async function regist() {
    try {
      // 出荷確定データ登録APIを実行
      const response = await execRegistShippingReceiptUnitApi();

      // レスポンスを画面に反映
      registrationResultInfo = {
        registrationType:
          registrationConditions.uploadMethod === NEW_OR_OVERWRITE
            ? response.summary.registrationType
            : response.summary.registrationType === RegistrationResultType.NEW
              ? RegistrationResultType.AUTO_NEW
              : response.summary.registrationType,
        registrationConditions: {
          toReceiveOn: registrationConditions.toReceiveOn,
          receiptLocationId: registrationConditions.receiptLocationId,
          sequentialNumber: registrationConditions.sequentialNumber,
        },
        registrationResult: response,
      };
      registButtonDisabled = true;
      await sleep(100);
      fetchShippingReceiptUnitList();

      // 次回デフォルトで選択状態にするために登録拠点IDを保存
      appContext.uploadedReceiptCenterId =
        registrationConditions.receiptLocationId;
      appContext.store();

      await tick();
      document
        .getElementById("registrationResult")
        ?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "alreadyBeenReceived"
      ) {
        // 荷受済みのものに対して上書き／追加登録を行おうとした場合
        toast.error($_("errors.alreadyBeenReceived.message"));
      } else if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "invalidAllTrackingNumbers"
      ) {
        // 新規登録時に全ての登録対象が不正な場合
        toast.error($_("errors.invalidAllTrackingNumbers.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
  }

  /**
   * 出荷確定データ登録APIを実行する
   * @returns {Promise<import("~/libs/backendApi").ShippingReceiptUnitResponse>}
   */
  async function execRegistShippingReceiptUnitApi() {
    const now = getCurrentDateTimeOnJst();
    return await backendApi.registShippingReceiptUnit(
      {
        trackingNumbers: readResultInfo.trackingNumberList,
        companyId: registrationConditions.uploaderEcId,
        receiptLocationId: registrationConditions.receiptLocationId,
        toReceiveOn: registrationConditions.toReceiveOn,
        sequentialNumber: registrationConditions.sequentialNumber,
        createdAt: formatDate(now, "yyyy-MM-dd HH:mm:ss", {
          locale: localeJa,
        }),
      },
      registrationConditions.uploadMethod,
    );
  }

  /**
   * 登録結果の表示、ファイルの選択状況をクリアする
   */
  function clearRegisterd() {
    if (
      window.confirm(
        "登録結果に表示されている情報はクリアされます。よろしいですか？",
      )
    ) {
      loadingProgress.wrapAsync(resetDisplay)();
    }
  }

  /**
   * 同じファイルを連続で選択できるようにvalueを初期化する
   * @param {Event} event
   */
  function handleFileClick(event) {
    /** @type {HTMLInputElement} */ (event.target).value = null;
  }

  /**
   * 最新の荷受け一覧情報を取得し、画面に反映する
   */
  async function fetchShippingReceiptUnitList() {
    try {
      // 荷受け一覧情報の取得
      shippingReceiptUnitList =
        (await backendApi.getShippingReceiptUnitList()) ?? [];
      dispatch("changedShippingReceiptUnitList", {
        value: shippingReceiptUnitList,
      });

      filterShippingReceiptUnitList();
    } catch (error) {
      // エラーメッセージのみ表示して継続
      console.warn(error);
      toast.error($_("errors.failedGetRegisteredShippingReceiptUnit.message"));
    }
  }

  /**
   * 画面の表示を初期状態に戻す
   */
  async function resetDisplay() {
    fileName = "ファイルが選択されていません。";
    readResultInfo = undefined;
    registrationResultInfo = undefined;
  }
</script>

<div class="businessArea">
  <div class="uploadArea" class:uploadAreaFilter={registrationResultInfo}>
    <div class="item">
      <div class="itemName">登録条件</div>
      <div class="registrationConditions">
        <div class="conditionsRecord">
          <div class="condition">
            <div class="conditionName">切離し日</div>
            <div class="conditionData">
              <input
                id="separationDate"
                type="date"
                class="inputSeparationDate"
                min={formatDate(today, "yyyy-MM-dd", {
                  locale: localeJa,
                })}
                bind:value={registrationConditions.toReceiveOn}
              />
            </div>
          </div>
          <div class="condition">
            <div class="conditionName wide">切離し拠点</div>
            <div class="conditionData">
              <select
                id="separatorCenter"
                class="inputSeparationCenter"
                bind:value={selectedReceiptLocationId}
              >
                <option value="" selected disabled>選択してください</option>
                {#each receiptLocationList as { prefecture, centers }}
                  <optgroup label={prefecture}>
                    {#each centers as { id, name }}
                      <option value={id}>{name}</option>
                    {/each}
                  </optgroup>
                {/each}
              </select>
            </div>
          </div>
        </div>
        <div class="conditionsRecord">
          <div class="condition">
            <div class="conditionName">登録回</div>
            <div class="conditionData">
              <input
                id="uploadNumber"
                type="number"
                class="inputUploadNumber"
                min="1"
                bind:value={registrationConditions.sequentialNumber}
              />
              <span class="uploadNumberUnit">回目</span>
            </div>
          </div>
          <div class="condition">
            <div class="conditionName wide">登録方法</div>
            <div class="conditionData">
              <div class="uploadMethod">
                <SegmentedButton
                  segments={[NEW_OR_OVERWRITE, ADDITIONAL]}
                  let:segment
                  singleSelect
                  bind:selected={registrationConditions.uploadMethod}
                >
                  <Segment {segment}>
                    <Label>{$_(`pages.Upload.uploadMethod.${segment}`)}</Label>
                  </Segment>
                </SegmentedButton>
              </div>
            </div>
          </div>
        </div>
        {#if filteredShippingReceiptUnitList.length > 0}
          <div class="conditionsRecord">
            <div class="registeredInfoArea">
              <p class="material-icons md-18">info</p>
              <div class="registeredInfo">
                <div class="registeredInfoMessage">
                  同じ切離し日・拠点で荷受けが済んでいない荷物があります。<br
                  />同時に荷受けする場合は追加登録を行ってください。
                </div>
                <div class="registeredInfoTitle">
                  {`${formatStringDate(
                    registrationConditions.toReceiveOn,
                    "yyyy/MM/dd(E)",
                    {
                      locale: localeJa,
                    },
                  )} ${getLocationName(
                    receiptLocationList,
                    registrationConditions.receiptLocationId,
                  )} 登録済み情報`}
                </div>
                <div class="registeredInfoContents">
                  {#each filteredShippingReceiptUnitList as { sequentialNumber, numberOfPackages, createdAt }}
                    <p class="registeredInfoContent">
                      <strong
                        >{`${sequentialNumber.toLocaleString()}回目`}</strong
                      >{`：荷物数${numberOfPackages.toLocaleString()}個 (最終更新日時 ${formatStringDate(
                        createdAt,
                        "yyyy/MM/dd(E)HH:mm",
                        {
                          locale: localeJa,
                        },
                      )})`}
                    </p>
                  {/each}
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div class="item">
      <div class="itemName">対象ファイル</div>
      <div class="uploadFile">
        <div class="uploadFileRecord">
          <div>
            <input type="text" id="fileName" disabled value={fileName} />
          </div>
          <label class="customButton">
            <input
              type="file"
              name="file"
              id="file"
              accept=".xlsx"
              bind:files
              on:click={handleFileClick}
            />ファイルを選択
          </label>
        </div>
        <div class="uploadFileRecord">
          <div class="fileAdvice">
            <p class="material-icons md-18">info</p>
            <p class="text">
              {#if shippingRreceiptUnitUploadCsvHasHeader}
                1行目はヘッダ行として読み込みません。
              {:else}
                ヘッダ行無しで1行目から読み込みます。
              {/if}
              また、{shippingReceiptUnitUploadCsvTrackingNumberColumnNumber}列目を送り状番号として読み込みます。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="itemName">プレビュー</div>
      <div class="readResultArea">
        {#if !readResultInfo}
          <p>ファイル選択後に表示されます。</p>
        {:else}
          <p>
            <span class="success">
              <span class="material-icons md-18">check_circle_outline</span>
              <strong
                >登録対象：{readResultInfo.trackingNumberList.length.toLocaleString()}件</strong
              >
            </span>
            {#if readResultInfo.trackingNumberList.length > 0}（
              <span class="trackingNumber"
                >{formatTrackingNumber(
                  readResultInfo.trackingNumberList[0],
                )}</span
              >{#if readResultInfo.trackingNumberList.length > 1}
                ～ <span class="trackingNumber"
                  >{formatTrackingNumber(
                    readResultInfo.trackingNumberList[
                      readResultInfo.trackingNumberList.length - 1
                    ],
                  )}</span
                >{/if} ）
            {/if}
          </p>
          {#if readResultInfo.emptyErrorList.length + readResultInfo.formatErrorList.length > 0}
            <p>
              <span class="skip">
                <span class="material-icons md-18">highlight_off</span>
                <strong
                  >登録対象外：{(
                    readResultInfo.emptyErrorList.length +
                    readResultInfo.formatErrorList.length
                  ).toLocaleString()}件</strong
                >
              </span>
              {#if readResultInfo.emptyErrorList.length > 0}
                <div class="skipDetail">
                  ・値なし：{readResultInfo.emptyErrorList.length.toLocaleString()}件（
                  {#each readResultInfo.emptyErrorList as index, i}{i > 0
                      ? "、"
                      : ""}{index.toLocaleString()}行目{/each} ）
                </div>
              {/if}
              {#if readResultInfo.formatErrorList.length > 0}
                <div class="skipDetail">
                  ・形式不備：{readResultInfo.formatErrorList.length.toLocaleString()}件（
                  {#each readResultInfo.formatErrorList as { index, content }, i}{i >
                    0
                      ? "、"
                      : ""}{index.toLocaleString()}行目<span
                      class="trackingNumber">{content}</span
                    >{/each} ）
                </div>
              {/if}
            </p>
          {/if}
        {/if}
      </div>
    </div>
  </div>
  <div class="submitButtonArea">
    <label class="customButton" class:disabledButton={registButtonDisabled}>
      <input
        type="submit"
        name="submit"
        id="submit"
        style="display: none;"
        on:click={loadingProgress.wrapAsync(regist)}
      />登録
    </label>
  </div>
  {#if registrationResultInfo}
    <div style="height: 15px;" />
    <div class="captionContainer">
      <div class="captionArea" id="registrationResult">登録結果</div>
    </div>
    <div class="resultArea">
      <!-- 登録結果 -->
      <div class="registrationTypeAndButton">
        <div class="registrationType">
          {$_(
            `pages.Upload.registrationTypeMessage.${registrationResultInfo.registrationType}`,
          )}
        </div>
        <div class="topButtonArea">
          <label class="clearButton">
            <button
              type="button"
              id="file"
              on:click={clearRegisterd}
            />次の出荷データを登録する
          </label>
        </div>
      </div>

      <div class="registrationResultBox">
        <!-- 基本情報 -->
        <div class="registrationResultConditions">
          {`${formatStringDate(
            registrationResultInfo.registrationConditions.toReceiveOn,
            "yyyy/MM/dd(E)",
            {
              locale: localeJa,
            },
          )} ${getLocationName(
            receiptLocationList,
            registrationResultInfo.registrationConditions.receiptLocationId,
          )} ${registrationResultInfo.registrationConditions.sequentialNumber}回目`}
        </div>

        <div class="registrationResultInfo">
          <!-- 登録件数 -->
          <div class="registrationResultCount">
            <div class="success">
              <span class="material-icons md-18">check_circle_outline</span>
              登録成功：{registrationResultInfo.registrationResult.summary
                ?.success ?? 0}件
            </div>
            {#if registrationResultInfo.registrationResult.numberOfPackagesByLocation?.length > 0}
              <span class="detailSubject">登録後の荷物個数内訳</span>
            {/if}
            {#each registrationResultInfo.registrationResult.numberOfPackagesByLocation as { locationId, count }}
              <div class="detail">
                ・{getLocationName(locationList, locationId)} 宛：{count}個
              </div>
            {/each}
          </div>

          <!-- スキップ情報 -->
          {#if registrationResultInfo.registrationResult.summary?.failure > 0}
            <div class="registrationResultSkip">
              <div class="devideLine" />
              <div class="all">
                <span class="material-icons md-18">highlight_off</span>
                スキップ：{registrationResultInfo.registrationResult.summary
                  .failure}件
              </div>
              {#if registrationResultInfo.registrationResult.failure?.notExistsTrackingNumber?.length > 0}
                <div class="detail">
                  ・出荷登録されていない荷物：
                  {#each registrationResultInfo.registrationResult.failure.notExistsTrackingNumber as trackingNumber, i}
                    {i > 0 ? "、" : ""}
                    {formatTrackingNumber(trackingNumber)}
                  {/each}
                </div>
              {/if}
              {#if registrationResultInfo.registrationResult.failure?.registeredOtherReceiptId?.length > 0}
                <div class="detail">
                  ・別の登録条件で登録済みの荷物：
                  {#each registrationResultInfo.registrationResult.failure.registeredOtherReceiptId as trackingNumber, i}
                    {i > 0 ? "、" : ""}
                    {formatTrackingNumber(trackingNumber)}
                  {/each}
                </div>
              {/if}
              {#if registrationResultInfo.registrationResult.failure?.received?.length > 0}
                <div class="detail">
                  ・既に荷受けが完了している荷物：
                  {#each registrationResultInfo.registrationResult.failure.received as trackingNumber, i}
                    {i > 0 ? "、" : ""}
                    {formatTrackingNumber(trackingNumber)}
                  {/each}
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .businessArea {
    display: columns;
  }
  .captionContainer {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .captionArea {
    background-color: #064491cb;
    width: 180px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }
  .uploadArea {
    width: 758px;
    height: fit-content;
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 10px 15px;
    display: columns;
  }
  .uploadAreaFilter {
    filter: brightness(0.8);
    pointer-events: none;
  }
  .item {
    display: flex;
    position: relative;
    width: 100%;
    margin: 2px 0;

    .registrationConditions {
      display: column;
      padding: 3px 0;

      .conditionsRecord {
        display: flex;

        .condition {
          display: flex;

          .conditionName {
            padding: 15px 2px 0 0;
            margin: 1px 3px 1px 10px;
            width: 60px;
            text-align: right;
            color: #242424;
            font-size: smaller;
            font-weight: 900;
          }

          .wide {
            width: 70px;
          }

          .conditionData {
            display: flex;

            .inputSeparationDate {
              width: 140px;
              margin: 4px 2px;
              height: 32px;
              font-size: 15px;
              padding-left: 10px;
            }
            .inputSeparationCenter {
              width: 282px;
              margin: 4px 2px;
              height: 35px;
            }
            .inputUploadNumber {
              width: 105px;
              margin: 4px 2px;
              height: 32px;
              font-size: 15px;
              padding-left: 10px;
            }
            .uploadNumberUnit {
              width: 30px;
              margin-left: 2px;
              padding-top: 18px;
              font-size: smaller;
            }
            .uploadMethod {
              height: 32px;
              margin: -10px 0 0 5px;

              :global(.mdc-segmented-button__label) {
                margin: 0 11px;
              }
            }
          }
        }
        .registeredInfoArea {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 588px;
          padding: 8px 3px;
          background-color: #f3f8fc;
          font-size: smaller;
          color: #242424;
          margin: 4px 0 0 10px;
          border-radius: 5px;

          .material-icons {
            color: #2b81d6;
            margin: 0 7px;
          }

          .registeredInfo {
            width: 100%;
            max-width: 579px;
            margin: 3px 0;
            background-color: #f3f8fc;
            font-size: smaller;
            color: #242424;
            border-radius: 5px;
            text-align: left;
            overflow-x: auto;

            .registeredInfoMessage {
              font-size: small;
              color: #242424;
              margin: 0 0 7px 3px;
              line-height: 1.2;
            }

            .registeredInfoTitle {
              font-size: smaller;
              font-weight: 900;
              min-width: 70px;
              color: #242424;
              margin: 0 10px 0 5px;
              line-height: 1.5;
            }

            .registeredInfoContents {
              display: flex;
              font-size: smaller;
              line-height: 1.5;
              color: #242424;
              margin: 0 10px 0 5px;
            }

            .registeredInfoContent {
              line-height: 1.5;
              font-size: 12px;
              color: #242424;
              border-radius: 2px;
              background-color: rgb(221, 236, 255);
              padding: 3px 5px;
              margin: 3px 5px 0 0;
              min-width: max-content;
            }
          }
        }

        .text {
          font-size: 13px;
          line-height: 1.2;
          color: #242424;
        }
      }
    }
    .uploadFile {
      display: column;
      padding: 3px 0;

      .uploadFileRecord {
        display: flex;

        input[type="text"] {
          width: 100%;
          height: 35px;
          margin: 4px 10px;
          font-size: 15px;
          padding-left: 10px;
          border: 1px solid #bdbdbdcb;
          border-radius: 2px;
        }

        .fileAdvice {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 8px 3px;
          background-color: #f3f8fc;
          font-size: smaller;
          color: #242424;
          margin: 4px 0 0 10px;
          border-radius: 5px;

          .material-icons {
            color: #2b81d6;
            margin: 0 7px;
          }

          .text {
            font-size: 13px;
            line-height: 1.2;
            color: #242424;
          }
        }
      }
    }
    .readResultArea {
      padding: 6px 10px;
      margin: 4px 5px 4px 10px;
      width: 100%;
      height: fit-content;
      border: 1px solid #bdbdbdcb;
      border-radius: 3px;
      font-size: small;
      line-height: 1.5;
      overflow: auto;

      .success {
        color: green;
      }

      .skip {
        color: rgb(206, 66, 50);
      }

      .material-icons {
        vertical-align: middle;
      }

      .trackingNumber {
        padding: 1px 3px;
        background-color: #e7ecf8;
        border-radius: 5px;
        line-height: 1.2;
      }

      .skipDetail {
        display: flex;
        margin-left: 10px;
        width: max-content;
      }
    }
  }
  .submitButtonArea {
    width: 800px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .itemName {
    display: flex;
    background-color: #b4d0f1cb;
    width: 150px;
    min-width: 150px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
    margin-top: 1px;
    align-items: center;
    justify-content: center;
  }
  #file {
    position: absolute;
    opacity: 0;
    width: 10px;
  }
  .customButton {
    display: flex;
    width: 170px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
  .customButton:hover,
  .customButton:focus,
  .clearButton:hover,
  .clearButton:focus {
    filter: brightness(1.2);
  }
  .disabledButton {
    filter: brightness(0.6);
    pointer-events: none;
  }
  #fileName {
    margin: 2px 10px;
    height: 33px;
    width: 400px;
  }
  .resultArea {
    width: 758px;
    min-width: 758px;
    height: fit-content;
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 10px 15px;
    display: columns;
    font-size: smaller;

    .registrationTypeAndButton {
      display: flex;
      position: relative;
      font-size: 15px;
      color: #064491cb;
      font-weight: bold;
      line-height: 1.3;
      width: 100%;

      .registrationType {
        margin: 13px 0 17px 0;
      }

      .topButtonArea {
        display: flex;
      }
      .topButtonArea .clearButton {
        position: absolute;
        right: 0;
        width: 180px;
        height: 27px;
        margin: 0 0 0 auto;
        padding-top: 15px;
        text-align: center;
        background: #369;
        color: #fff;
        cursor: pointer;
        font-size: smaller;
        border-radius: 8px 8px 8px 8px;
      }
    }
    .registrationResultBox {
      border: 1px solid #bdbdbdcb;
      border-radius: 4px;

      .registrationResultConditions {
        font-size: 15px;
        background-color: #b4d0f1cb;
        padding: 8px 10px;
        line-height: 1.3;
        border-radius: 3px 3px 0 0;
      }
      .registrationResultInfo {
        padding: 10px 13px;
        line-height: 1.5;
        border-radius: 0 0 4px 4px;
        background-color: #f3f8fc;

        .registrationResultCount {
          display: flex;
          flex-direction: column;
          line-height: 1.3;
          .success {
            font-weight: bold;
            color: green;
            .material-icons {
              vertical-align: middle;
            }
          }
          .detailSubject {
            margin: 6px 0 0 10px;
          }
          .detail {
            margin: 5px 0 0 10px;
          }
        }
      }
      .registrationResultSkip {
        display: flex;
        flex-direction: column;
        line-height: 1.3;
        .devideLine {
          border-top: 1px solid #bdbdbdcb;
          margin: 13px 0;
        }
        .all {
          font-weight: bold;
          line-height: 1.2;
          color: rgb(206, 66, 50);
          .material-icons {
            vertical-align: middle;
          }
        }
        .detail {
          margin: 5px 0 0 10px;
        }
      }
    }
  }
</style>
