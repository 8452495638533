<script>
  import { getContext, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import EcSwitchableInfo from "~/pages/Main/EcSwitchableInfo.svelte";
  import ConfirmedUploadAndDelete from "~/pages/Upload/ConfirmedUploadAndDelete.svelte";
  import PreUpload from "~/pages/Upload/PreUpload.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  const SUBJECT = userContext.hasEcAdminRole()
    ? "出荷データ登録"
    : "荷受けデータ登録";
  const PRE_UPLOAD = "事前出荷データ登録";
  const CONFIRMED_UPLOAD = userContext.hasEcAdminRole()
    ? "出荷確定登録"
    : "事前荷受け登録";
  const DELETE_INFO = "登録情報の削除";

  /** 選択可能なメニュー一覧 @type {Array<PRE_UPLOAD | CONFIRMED_UPLOAD | DELETE_INFO>} */
  const selectableMenu =
    userContext.hasEcAdminRole() && !userContext.hasAnkAdminRole()
      ? [PRE_UPLOAD]
      : [CONFIRMED_UPLOAD, DELETE_INFO];

  /** 切替え可能なEC事業者名 @type {string} */
  let switchableEcName;

  /** 選択中のメニュー @type {PRE_UPLOAD | CONFIRMED_UPLOAD | DELETE_INFO} */
  let active = selectableMenu[0];

  /** メニュー切替え可能な場合にtrue @type {boolean} */
  let switchable = true;

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  let shippingReceiptUnitList = [];

  /** 切離し日が2日以上前の登録情報があるかどうか @type {boolean} */
  let isOldData;

  /** 切離し日が２日以上前のデータがあることを示すエラートーストのID @type {number} */
  let toastId;

  function deletedShippingReceiptUnit() {
    checkExistOldData();
  }

  // ページ初期化処理
  loadingProgress.wrapAsync(async () => {
    if (!userContext.hasEcAdminRole()) {
      try {
        // 荷受け一覧情報の取得
        shippingReceiptUnitList =
          (await backendApi.getShippingReceiptUnitList()) ?? [];
      } catch (error) {
        // エラーメッセージのみ表示して継続
        console.warn(error);
        toast.error(
          $_("errors.failedGetRegisteredShippingReceiptUnit.message"),
        );
      }

      checkExistOldData();
    }
  })();

  onDestroy(() => {
    toast.popWithPageMove();
  });

  /**
   * 切離し日が2日以上前の登録情報があるかどうかをチェックし、トーストの表示制御を行う
   */
  function checkExistOldData() {
    isOldData = false;

    // 切離し日が2日以上前の登録情報があるかどうか
    for (const shippingReceiptUnit of shippingReceiptUnitList) {
      const toReceiveOn = new Date(shippingReceiptUnit.toReceiveOn);
      const today = new Date();
      const diff = today.getTime() - toReceiveOn.getTime();
      if (diff > 2 * 24 * 60 * 60 * 1000) {
        isOldData = true;
        break;
      }
    }

    if (isOldData && !toastId) {
      // 切離し日が2日以上前の登録情報がある、かつ既に表示されているトーストがない場合
      toastId = toast.error(
        "切離し日が2日以上前の登録情報が残っています。<br />不要な情報の場合「登録情報の削除」から削除してください。",
        {
          initial: 0,
          popsWhenPageMoved: true,
        },
      );
    } else if (!isOldData && toastId) {
      // 切離し日が2日以上前の登録情報がない、かつ既に表示されているトーストがある場合
      toast.pop(toastId);
      toastId = null;
    }
  }
</script>

<div class="wrapper">
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">{SUBJECT}</h1>
  </div>
  {#if userContext.loginUser.switchableCompanies?.length > 1}
    <EcSwitchableInfo bind:switchableEcName>
      <p slot="description">
        {#if userContext.hasAnkAdminRole()}
          事前データを登録する場合は {switchableEcName} ユーザーに切り替えてください。
        {:else}
          出荷確定データを登録する場合は ANK EXPRESS
          ユーザーに切り替えてください。
        {/if}
      </p>
    </EcSwitchableInfo>
  {/if}
  {#if switchable}
    <div class="captionTabs">
      {#each selectableMenu as menu}
        <input
          type="radio"
          name="selectableMenu"
          id={menu}
          bind:group={active}
          value={menu}
        />
        <label
          for={menu}
          class="selectableMenu"
          class:selectableMenu--badge={menu === DELETE_INFO && isOldData}
          class:hoverEffect={selectableMenu.length > 1}>{menu}</label
        >
      {/each}
    </div>
  {/if}
  {#if active === PRE_UPLOAD}
    <PreUpload bind:switchable />
  {:else if active === CONFIRMED_UPLOAD || active === DELETE_INFO}
    <ConfirmedUploadAndDelete
      {active}
      {shippingReceiptUnitList}
      on:deletedShippingReceiptUnit={deletedShippingReceiptUnit}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }
  .titleArea {
    height: 40px;
    display: flex;
    .titleLine {
      background-color: #064491cb;
      min-width: 15px;
      height: 40px;
      border-radius: 0px 0px 0px 0px;
    }
    .title {
      display: flex;
      font-size: x-large;
      margin: auto auto auto 10px;
      min-width: 500px;
    }
  }
  .captionTabs {
    margin-top: 15px;
    display: flex;
    justify-content: start;
    .selectableMenu {
      background-color: #0645917e;
      width: fit-content;
      min-width: fit-content;
      padding-right: 18px;
      height: 26px;
      border-radius: 10px 10px 0px 0px;
      padding-top: 12px;
      padding-left: 20px;
      color: #fff;
      font-weight: 900;
    }
    .selectableMenu--badge {
      position: relative;
    }
    .selectableMenu--badge::after {
      content: "";
      background-color: #bd362f;
      border-radius: 50%;
      display: inline-block;
      height: 16px;
      width: 16px;
      position: absolute;
      top: -5px;
      right: -5px;
    }
    input[name="selectableMenu"] {
      display: none;
    }
    input:checked + .selectableMenu {
      background-color: #064491cb;
    }
    .hoverEffect:hover {
      filter: brightness(1.2);
    }
  }
</style>
