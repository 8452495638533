<script>
  import IconButton from "@smui/icon-button";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").ShippingKnowledge} */
  export let record;
  /** @type {Function} */
  export let deletedResultsReplace;

  function showDialog() {
    confirm(
      "以下の住所に対するナレッジを削除します。よろしいですか？\r\n住所：" +
        displayAdressAndType(record.address, record.type),
    ) && deleteTargetRecord();
  }

  /**
   * ナレッジ削除APIを実行する。
   * @returns {Promise<void>}
   */
  const execDeleteShippingKnowledgeApi = loadingProgress.wrapAsync(async () => {
    let updateShipmentEvent;

    /** @type {import("~/libs/backendApi").DeleteKnowledgeRequest} */
    if (record.type === 0) {
      updateShipmentEvent = {
        byAddress: record.id,
      };
    } else {
      updateShipmentEvent = {
        neighborhood: record.id,
      };
    }

    return await backendApi.deleteShippingKnowledge(updateShipmentEvent);
  });

  /**
   * 対象のナレッジを削除する。
   */
  async function deleteTargetRecord() {
    /** @type {string} */
    let message;
    try {
      await execDeleteShippingKnowledgeApi();
      toast.info($_("message.deleteComplete"));
    } catch (error) {
      console.error("Error:", error);

      if (error instanceof HandledError) {
        message = error.message;
      } else {
        if (error instanceof HTTPError && error.response?.status == 400) {
          message = $_("errors.updateFailed.message");
        } else if (
          error instanceof HTTPError &&
          error.response?.status == 401
        ) {
          message = $_("errors.updateUnauthorized.message");
        } else if (
          error instanceof HTTPError &&
          error.response?.status == 403
        ) {
          message = $_("errors.updateForbidden.message");
        } else {
          message = $_("errors.updateDefaultMessage.message");
        }
      }
      toast.error(message);
    } finally {
      deletedResultsReplace(record);
    }
  }

  /**
   * 住所と種類をあわせて表示する。
   * @param {string} address 住所
   * @param {number} type 種類
   * @returns {string} 住所と種類
   */
  function displayAdressAndType(address, type) {
    let formattedAddressAndType;
    if (type === 0) {
      formattedAddressAndType = address;
    } else if (type === 1) {
      formattedAddressAndType = address + " (近隣)";
    }
    return formattedAddressAndType;
  }
</script>

<IconButton
  class="material-icons"
  size="button"
  style="font-size: 24px; color: #6e6e6e; margin-left: 4px;"
  on:click={showDialog}>delete</IconButton
>
