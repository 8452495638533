<script>
  import Button from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import Textfield from "@smui/textfield";
  import { format as formatDate } from "date-fns";
  import { HTTPError } from "ky";
  import { getContext, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").ShippingKnowledge} */
  export let targetResult;
  /** @type {Function} */
  export let updatedResultsReplace;
  /**
   * ダイアログを閉じるハンドラ
   * @type {function(): void}
   */
  export let dialogCloseHandler;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * ダイアログを開くかどうか
   * @type {boolean}
   */
  let open;
  /**
   * 保存ボタンを無効化するかどうか
   * @type {boolean}
   */
  let saveButtonDisabled = true;
  /**
   * テキストボックスにバインドされた共有配達メモの文面
   * @type {string}
   */
  let changedMemo = targetResult.memo;
  /**
   * 文字数超過のエラーメッセージを表示するかどうか
   * @type {boolean}
   */
  let errorMessage = false;
  /**
   * 共有配達メモの更新時間
   * @type {string}
   */
  let updatedAt;

  $: if (changedMemo !== targetResult.memo) {
    saveButtonDisabled = false;
  }
  $: if (changedMemo.length >= 500) {
    errorMessage = true;
    changedMemo = changedMemo.slice(0, 500);
  } else {
    errorMessage = false;
  }

  onMount(loadingProgress.wrapAsync(openDialog));

  async function openDialog() {
    open = true;
  }

  /**
   * ダイアログを閉じる前の処理を行う
   * @param {CustomEvent<{ action: string }>} event
   */
  const closeHandler = async (event) => {
    let newResult;
    /** @type {string} */
    let message;
    switch (event.detail.action) {
      case "save":
        try {
          await execStatusUpdateApi();

          newResult = Object.assign({}, targetResult);
          newResult.memo = changedMemo;
          newResult.updatedAt = updatedAt;
          if (userContext.hasShippingPartnerAdminRole()) {
            newResult.updatedBy = userContext.loginUser.displayName;
          }
          toast.info($_("message.updateComplete"));
        } catch (error) {
          if (error instanceof HandledError) {
            message = error.message;
          } else {
            if (error instanceof HTTPError && error.response?.status == 401) {
              message = $_("errors.updateUnauthorized.message");
            } else if (
              error instanceof HTTPError &&
              error.response?.status == 403
            ) {
              message = $_("errors.updateForbidden.message");
            } else {
              message = $_("errors.updateDefaultMessage.message");
            }
          }
          newResult = null;
          toast.error(message);
        } finally {
          dialogCloseHandler();
          updatedResultsReplace(newResult);
        }
        break;
      default:
        dialogCloseHandler();
        break;
    }
  };

  /**
   * 共有配達メモの更新APIを実行する
   */
  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    let updatedByAddressKnowledge;
    let updatedNeighborhoodKnowledge;
    updatedAt = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");

    if (targetResult.type === 0) {
      // 部屋ごとの共有配達メモを更新する場合
      updatedByAddressKnowledge = {
        id: targetResult.id,
        memo: changedMemo,
        updatedAt: updatedAt,
        receiverName: null,
      };
    } else {
      // 近隣の共有配達メモを更新する場合
      updatedNeighborhoodKnowledge = {
        id: targetResult.id,
        memo: changedMemo,
        updatedAt: updatedAt,
      };
    }

    let body = {
      byAddress: updatedByAddressKnowledge,
      neighborhood: updatedNeighborhoodKnowledge,
    };
    return await backendApi.updateShippingKnowledge(body);
  });
</script>

<div class="dialog">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%;"
  >
    <Title id="detail-dialog-title">編集</Title>

    <Content id="detail-dialog-content">
      {#if errorMessage}
        <div class="errorArea">
          <span class="material-icons"> report_problem </span>
          <p class="errorMessage">500文字以内で入力してください。</p>
        </div>
      {/if}
      <div class="addressArea">
        <p>
          住所：{targetResult.address}
          {#if targetResult.type === 1}
            <span class="neighborhood">(近隣)</span>
          {/if}
        </p>
      </div>
      <div class="editable-textfield">
        <Textfield
          textarea
          bind:value={changedMemo}
          maxlength="500"
          label="共有配達メモ"
          style="width: 500px; height: 12rem;"
        ></Textfield>
      </div>
    </Content>

    <Actions>
      <Button action={"cancel"} style="background-color: #D9D9D9; color: #333;"
        >キャンセル</Button
      >
      <Button
        action={"save"}
        touch
        variant="unelevated"
        bind:disabled={saveButtonDisabled}>保存</Button
      >
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .dialog {
    :global(.mdc-select--outlined .mdc-select__anchor) {
      width: 300px;
      height: 36px;
    }
  }

  .dialog {
    :global(.mdc-dialog .mdc-dialog__surface) {
      max-width: 700px;
    }
  }

  .editable-textfield {
    margin-top: 16px;
  }

  .errorArea {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 4px;
    padding: 8px 14px;
    margin: 8px 0;
    font-weight: bolder;
    color: #c80000;
    background-color: #ffe7e7;
    border-radius: 4px;
    line-height: 20px;

    .material-icons {
      color: #c80000;
    }
  }

  .addressArea {
    color: #333;
    font-size: 16px;

    .neighborhood {
      color: #2b81d6;
      margin-left: 0.4px;
    }
  }
</style>
